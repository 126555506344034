/* eslint-disable react/jsx-no-target-blank */
import React, {useState} from 'react'
import { Link } from 'react-router-dom';

import Container from '@material-ui/core/Container';

import CurrencySelector from '../../CurrencySelector';

import SpotlioIcon from '../../Icon';
import MobileTabs from '../HeaderTabs/mobile';

import IntlMessage from '../../../LocaleEngine';

import { getSystemSettings } from '../../../utils/SettingsRetriever';
import { getCookie } from '../../../utils/CookiesRetriever';

import TATO from './TATO';

import './index.scss';

export default ({ childProps }) => {
    const [ menu, toggleMenu ] = useState(false);
    const configuration = getSystemSettings();

    let menuIcon = 'sp-menu';
    if(! !configuration.assets.mobile.menu_icon) menuIcon = configuration.assets.mobile.menu_icon;

    let shoppingCartIcon = 'sp2-shopping-cart';
    if(! !configuration.assets.mobile.cart_icon) shoppingCartIcon = configuration.assets.mobile.cart_icon;

    let showLanguage = false;
    if(! !configuration.head.multilanguage) showLanguage = configuration.head.multilanguage;

    let showCurrency = false;
    if(! !configuration.head.multicurrency) showCurrency = configuration.head.multicurrency;

    let showProfile = false;
    if(! !configuration.head.profile) showProfile = configuration.head.profile;

    let phone = false;
    if(! !configuration.head.phone) phone = configuration.head.phone;

    const b2bCookie = JSON.parse(getCookie('b2b'));

    return (
        <header className="topbarWrapper">
            <MobileTabs toggleMenu={toggleMenu.bind(this)} menu={menu}/>
            <Container className="container" maxWidth="lg">
                <div onClick={()=>toggleMenu(true)} className="menu">
                    <SpotlioIcon icon={menuIcon} />
                </div>
                <div className="logo">
                    <a target="_blank" href={configuration.head.site_url} title={configuration.head.title}>
                        <img alt={configuration.head.title} src={configuration.assets.logo || "https://shopspotlio.spotlio.com/bundles/spotliowebappfront/spotlio/images/logo-copper.png"} />
                    </a>
                </div>

                <div className="cart">
                    {window.location.pathname.split("/")[2] === "activities" ?
                        null :
                        <span className="fa-stack has-badge" data-count={0}>
                            <Link to={'/cart'} className="shoppingcart">
                                <SpotlioIcon icon={shoppingCartIcon} className="shoppingcart" />
                            </Link>
                        </span>
                    }
                </div>
                <div className="options">
                    {
                        b2bCookie
                        ? (
                            <TATO b2bCookie={b2bCookie} />
                        ) : (
                            <React.Fragment>
                                <div className="message">
                                    {
                                        phone ?
                                        <React.Fragment>
                                            <IntlMessage id="skin.global.header.contact" />:  <strong><a href={"tel:" + phone.tel}>{phone.phone}</a></strong>
                                        </React.Fragment>
                                        : ''
                                    }
                                </div>
                                <div className="links">
                                    <ul>
                                        {showCurrency ? <li><CurrencySelector mode="b2c" /></li> : ''}
                                        {showLanguage ? <li><a href="/#" title="Language"><IntlMessage id="header.info.language" /></a></li> : ''}
                                        {showProfile ? <li><a href="/#" title="My Account"><IntlMessage id="header.info.my_account" /></a></li> : ''}
                                    </ul>
                                </div>
                            </React.Fragment>
                        )
                    }
                </div>
            </Container>
        </header>
    )
};
