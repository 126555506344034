import React from 'react'

import Modal from '@material-ui/core/Modal';
import {X as XIcon} from 'react-feather';

import './index.scss';

export default ({ className, title, open, children, closeFn, footerContent }) => {
    let isOpen = open;

    return(
    <Modal open={isOpen} disablePortal={true}>
        <div className={"generalModal " + className}>
            <div className="header">
                {title}
                <XIcon size={32} onClick={ closeFn } />
            </div>
            <div className="content">
                {children}
            </div>
            <div className="footer">
                {footerContent}
            </div>
        </div>
    </Modal>
    );
}
