export default () => {
    const path = window.location.href.split("//")[1].split("/")[1];
    if (path.length === 2) {
        if (path.substring(0,1) === 'v')
            return 'en';
        else
            return path;
    }
    return 'en';
};
