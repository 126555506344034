// core and third party
import React from 'react';
import { ThemeProvider as MaterialUIProvider } from '@material-ui/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

// project
import SEO from '../Seo';
import TopBar from './Topbar';
import HeaderTabs from './HeaderTabs';
import Footer from './Footer';
import Socket from './Socket';
import MaterialUITheme from './Themes/materialui';
import ClientTheme from './Themes/client';

// styles
import './index.scss';
import './styles/responsive.scss';

export default class Layout extends React.Component {
  render() {
    return (
      <MaterialUIProvider theme={MaterialUITheme}>
        <CssBaseline />
        <ClientTheme system={this.props.system} className="main-application">
          <SEO title="Loading..." keywords={[`spotlio`, `booking`]} system={this.props.system} config={this.props.config} />

          <TopBar />

          <HeaderTabs />

          <Container maxWidth="lg" className="viewWrapper" component="section">
            {this.props.children}
          </Container>

          <Footer />
          <Socket />

        </ClientTheme>
      </MaterialUIProvider>
    );
  }
}
