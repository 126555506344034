import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Router, Switch, Route } from 'react-router-dom';

import LoadingScreen from '../components/Layout/LoadingScreen';

import history from '../history';

import Layout from './components/Layout';

// b2b pages
const B2BHome = lazy(() => import('./containers/Home'));
const B2BAdminSignup = lazy(() => import('./containers/Home/AdminSignUp'));
const B2BDashboard = lazy(() => import('./containers/Dashboard'));
const B2BDashboardGuests = lazy(() => import('./containers/Dashboard/Guests'));
const B2BDashboardItineraries = lazy(() => import('./containers/Dashboard/Itineraries'));
const B2BDashboardPromocodes = lazy(() => import('./containers/Dashboard/Promocodes'));
// b2b admin pages
const B2BAdminHome = lazy(() => import('./containers/AdminPanel/TravelAgents'));
const B2BAdminAgent = lazy(() => import('./containers/AdminPanel/PromoCodes'));
// b2b not found
const NotFound = lazy(() => import('./containers/NotFound'));

export default () => {
    return (
        <BrowserRouter>
            <Router history={history}>
                <Suspense fallback={<LoadingScreen />} >
                    <Layout>
                        <Switch>
                            {/* TravelAgent */}
                            <Route exact path="/b2b" component={B2BHome} />
                            <Route exact path={["/b2b/dashboard", "/b2b/home"]} component={B2BDashboard} />
                            <Route exact path={["/b2b/dashboard/guests", "/b2b/home/guests"]} component={B2BDashboardGuests} />
                            <Route exact path={["/b2b/dashboard/itineraries", "/b2b/home/itineraries"]} component={B2BDashboardItineraries} />
                            <Route exact path={["/b2b/dashboard/promocodes", "/b2b/home/promocodes"]} component={B2BDashboardPromocodes} />
                            <Route exact path="/b2b/dashboard/guests" component={B2BDashboard} />
                            {/* Adminland */}
                            <Route exact path="/b2b/admin" component={B2BAdminSignup} />
                            <Route exact path="/b2b/admin/agents" component={B2BAdminHome} />
                            <Route exact path="/b2b/admin/agents/:agentId/:channelId" component={B2BAdminAgent} />
                            {/* Not found */}
                            <Route component={NotFound} />
                        </Switch>
                    </Layout>
                </Suspense>
            </Router>
        </BrowserRouter>
    )
}
