import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import App from './containers/App';

import packageJson from '../package.json';
global.appVersion = packageJson.version;

ReactDOM.render(<App />, document.getElementById('root'), () => {
    if (process.env.NODE_ENV === 'development') {
        console.log(
            "     ____              _    _             \n" +
            "    |  _ \\            | |  (_)            \n" +
            "    | |_) | ___   ___ | | ___ _ __   __ _ \n" +
            "    |  _ < / _ \\ / _ \\| |/ / | '_ \\ / _` |\n" +
            "    | |_) | (_) | (_) |   <| | | | | (_| |\n" +
            "    |____/ \\___/ \\___/|_|\\_\\_|_| |_|\\__, |\n" +
            "                                     __/ |\n" +
            "                                    |___/ \n" +
            "version " + global.appVersion + " - " + process.env.NODE_ENV + "\n"
        );
    } else {
        localStorage.removeItem("system");
        localStorage.removeItem("config");
        localStorage.removeItem("currencyDatabase");
        localStorage.removeItem("__localesLoaded");
    }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
