import React from 'react';

import Agrupation from './agrupation';

export default function Products(props){

    function removeItemModalOpen(item, value){
        props.deleteItem(item, value)
    }
    return (
        <div className="products">
            {props.cart.map((agrupation, idx) => {
                return(<Agrupation key={idx} deleteItem={removeItemModalOpen.bind(this)} agrupation={agrupation} idx={idx}/>)
            })}
        </div>
    )
}