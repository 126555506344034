import React from 'react';

import CartActivity from './activity';
import CartLodging from './lodging';

export default function CartCell(props){
const { item, index, agrupation } = props

function removeItemModalOpen(item, value){
    props.deleteItem(item, value)
}

    if (item.isLodging === false){
        if (item.isAir === false){
           return ( <CartActivity deleteItem={removeItemModalOpen.bind(this)} agrupation={agrupation} index={index} item={item}/> )
        } else {
            // air cell 
        }
    } else {
        return (<CartLodging deleteItem={removeItemModalOpen.bind(this)} agrupation={agrupation} index={index} item={item}/>)
    }
}
