import { getCookie } from './CookiesRetriever';
import { getSystemSettings } from './SettingsRetriever';

const CheckProtected = {
    getAuth() {
        let isLoginRequired = false;
        let configuration = getSystemSettings();
        if(configuration.b2b){
            if(configuration.b2b.is_private){
                isLoginRequired = true;
            }
        }

        if(!isLoginRequired) return true;

        let userCookie = JSON.parse(getCookie('b2b'));
        if(userCookie) return true;
        else return false;
    }
};

export default CheckProtected;