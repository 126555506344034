import request from './request';
import { setCustomerConfig, setSystemSettings, setUserSettings, getUserSettings } from './SettingsRetriever';

const MakeQuerablePromise = (promise) => {
    // Don't modify any promise that has been already modified.
    if (promise.isResolved) return promise;

    // Set initial state
    var isPending = true;
    var isRejected = false;
    var isFulfilled = false;

    // Observe the promise, saving the fulfillment in a closure scope.
    var result = promise.then(
        function(v) {
            isFulfilled = true;
            isPending = false;
            return v;
        },
        function(e) {
            isRejected = true;
            isPending = false;
            throw e;
        }
    );

    result.isFulfilled = function() { return isFulfilled; };
    result.isPending = function() { return isPending; };
    result.isRejected = function() { return isRejected; };
    return result;
}

export default () => MakeQuerablePromise(new Promise((resolve, reject) => {
    const url = window.location.href.split('//')[1].split('/')[0].split(':')[0];
    request({
        url: 'https://s3.amazonaws.com/spotlio-react-booking-configurations/clients.json',
        method: 'GET'
    }).then(Config => {
        let ret = false;
        let hasCustomer = false;
        Config.data.forEach(customer => {
            if (customer.urls.includes(url)) {
                hasCustomer = true;
                request({
                    url: 'https://s3.amazonaws.com/spotlio-react-booking-configurations/customers/' + customer.name + '/' + customer.stage + '.json',
                    method: 'GET'
                }).then(customerConfig => {
                    ret = { customer: customer, config: customerConfig.data };
                    setCustomerConfig(ret);
                    request({
                        url: 'https://api-management.spotlio.com/api/programmatic/getreactconfig?apikey=a1e8ed6fa348a20bec841ffaf0e9957a251d8e5c4420&alias=' + ret.customer.name,
                        method: 'GET'
                    }).then(result => {
                        setSystemSettings(result.data);
                        if (!getUserSettings()) {
                            setUserSettings({
                                currencies: {
                                    selected: result.data.app.currency.default
                                }
                            });
                        }
                        resolve(ret);
                    })
                }).catch(err => reject('no destination found'))
            }
        })
        if (!hasCustomer) reject('no destination found')
    }).catch(err => {
        console.error('err', err);
        reject(err);
    });
}));
