import React from 'react';

import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';

import { getUserSettings, setUserSettings, getSystemSettings } from '../../utils/SettingsRetriever';

import Modal from '../Modal';
import IntlMessage from '../../LocaleEngine';

import './index.scss';

export default class CurrencySelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCurrency: getUserSettings().currencies.selected,
            currenciesList: getSystemSettings().app.currency,
            displayModal: false
        };
    }

    handleUpdateSystemSettings = () => {
        const UserSettings = getUserSettings();
        setUserSettings({
            ...UserSettings,
            currencies: {
                ...UserSettings.currencies,
                selected: this.state.selectedCurrency
            }
        });
        this.setState({...this.state});
        window.location.reload();
    }

    handleToggleCurrencyModal = () => {
        const modalState = !this.state.displayModal;
        this.setState({...this.state, displayModal: modalState});
    }

    handleSelectCurrency = (index) => {
        this.setState({...this.state, selectedCurrency: index, displayModal: false}, () => this.handleUpdateSystemSettings());
    }

    handleRenderCurrenciesList = () => {
        const currentlySelectedCurrencyIndex = this.state.selectedCurrency;
        return this.state.currenciesList.list.map((val, idx) => {
            return (
                <div key={idx} className={"CurrencyItem " + (idx === currentlySelectedCurrencyIndex ? "Selected" : "")} onClick={() => this.handleSelectCurrency(idx)}>
                    {val.name} ({val.base}) {idx === currentlySelectedCurrencyIndex && (<IntlMessage id="currency.modal.currently_selected" />)}
                </div>
            )
        })
    }

    render() {
        let CurrencyLink = '';
        if (this.props.mode === "b2b") {
            CurrencyLink = <Avatar className={(this.props.className || '') + " CurrencySelectorButton Avatar"} onClick={() => this.handleToggleCurrencyModal()}>{this.state.currenciesList.list[this.state.selectedCurrency].base}</Avatar>
        } else {
            CurrencyLink = <Button className={(this.props.className || '') + " CurrencySelectorButton Link"} onClick={() => this.handleToggleCurrencyModal()}><IntlMessage id="currency.buttons.currency" /></Button>
        }

        return (
            <React.Fragment>
                { CurrencyLink }
                <Modal
                    open={this.state.displayModal}
                    title={<IntlMessage id="header.currency.title" />}
                    className="CurrenciesModal"
                    closeFn={() => this.handleToggleCurrencyModal()}
                    footerContent={
                        <React.Fragment>
                            <Button onClick={() => this.handleToggleCurrencyModal()} className="secondary">
                                <IntlMessage id="profile.itinerary.modal.close" />
                            </Button>
                        </React.Fragment>
                    }
                >
                    <div className="body">
                        { this.handleRenderCurrenciesList() }
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}
