import React from 'react';
import moment from 'moment';
import ReactFlagsSelect from 'react-flags-select'

import MaterialModal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import B2CModal from '../../../../components/Modal';

import SpotlioIcon from '../../../../components/Icon';

import { getSystemSettings } from '../../../../utils/SettingsRetriever';
import request from '../../../../utils/request';
import { getCookie } from '../../../../utils/CookiesRetriever';
import SalesIDRetriever from '../../../../utils/SalesIDRetriever';

import 'react-flags-select/scss/react-flags-select.scss';
import './Guests.scss';

export default (props) => {
    const emptyGuestForm = {
        isEdit: false,
        firstName: '',
        middleName: '',
        lastName: '',
        emailAddress: '',
        dateOfBirth: '',
        address: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        homePhone: '',
        mobilePhone: '',
        workPhone: '',
        fax: '',
        memberNumber: '',
        error: ''
    };

    const [ guestForm, setGuestForm ] = React.useState(emptyGuestForm);
    const [ userCookie ] = React.useState(JSON.parse(getCookie('b2b')));
    const [ isModalOpen, setModalState ] = React.useState(false);
    const [ countries, setCountries ] = React.useState([]);
    const [ regions, setRegions ] = React.useState([]);
    const [ countriesRetrieved, setCountriesRetrieved ] = React.useState(false);
    const [ forceModalSpawn, setForceModalSpawn ] = React.useState(false);

    const handleSaveCreateGuest = (isActive = false) => {
        let url = getSystemSettings().api + '/inntopia/travel-agent/guest/create';
        let uploadData = guestForm;
        uploadData.token = userCookie.token.token;
        uploadData.salesId = userCookie.channel;

        if(uploadData.isEdit){
            url = getSystemSettings().api + '/inntopia/travel-agent/guest/update';
            uploadData.guestId = uploadData.id;
        }

        request({
            url: url,
            method: 'POST',
            data: uploadData
        })
        .then( (data) => {
            setForceModalSpawn(false);
            setModalState(false);
            props.onEditConfirm(data);
        });
    }

    const getCountries = () => {
        request({
            url: getSystemSettings().api + '/inntopia/getCountries',
            method: 'GET',
        })
        .then((data) => {
            let countries = []
            data.data.forEach((country) => {
                countries.push(country.id)
            })
            setCountries(countries);
        });
    }

    const handleFormChange = (event, name) => {
        setGuestForm({...guestForm, [name]: event.target.value});
    }

    const setCountry = (code) => {
        handleFormChange({target: {name: 'country', value: code}}, 'country');
        request({
            url: getSystemSettings().api + '/inntopia/getStates?state=' + code,
            method: 'GET',
        })
        .then((data) => {
            let regions = [];
            data.data.forEach((region) => {
                regions.push(region)
            })
            setRegions(regions);
        })
    }

    const setRegion = (region) => {
        handleFormChange({target: {name: 'region', value: region}}, 'region');
    }

    const handleEditUserModal = (id) => {
        request({
            url: getSystemSettings().api + '/inntopia/travel-agent/guest',
            method: 'POST',
            data: {
                token: userCookie.token.token,
                salesId: SalesIDRetriever(),
                guestId: id
            }
        })
        .then((data) => {
            if (data.status === 200) {
                let guestForm = data.data;
                guestForm.isEdit = true;
                guestForm.dateOfBirth = moment(guestForm.dateOfBirth).format('YYYY-MM-DD');

                setGuestForm(guestForm);
                setModalState(true);
            }
        });
    }

    const handleCloseModal = () => {
        setGuestForm(emptyGuestForm);
        setModalState(false);
    }

    const handleRenderModalContent = () => {
        return (
            <React.Fragment>
                {
                    props.env === "b2b" && (
                        <Button className="CloseModal" onClick={() => handleCloseModal()}>
                            <SpotlioIcon icon="nav-close" />
                        </Button>
                    )
                }
                <div className="ModalContent">
                    <table>
                        <tbody>
                            <tr>
                                <td className="Description Required">First Name</td>
                                <td>
                                    <TextField value={guestForm.firstName} onChange={(e) => handleFormChange(e, "firstName")} className="CreateGuestInput" fullWidth required margin="normal" />
                                </td>
                            </tr>
                            <tr>
                                <td className="Description">Middle Name</td>
                                <td>
                                    <TextField value={guestForm.middleName} onChange={(e) => handleFormChange(e, "middleName")} className="CreateGuestInput" fullWidth margin="normal" />
                                </td>
                            </tr>
                            <tr>
                                <td className="Description Required">Last Name</td>
                                <td>
                                    <TextField value={guestForm.lastName} onChange={(e) => handleFormChange(e, "lastName")} className="CreateGuestInput" fullWidth required margin="normal" />
                                </td>
                            </tr>
                            <tr>
                                <td className="Description Required">Date of Birth</td>
                                <td>
                                    <TextField value={guestForm.dateOfBirth} onChange={(e) => handleFormChange(e, "dateOfBirth")} type="date" className="CreateGuestInput" fullWidth required margin="normal" />
                                </td>
                            </tr>
                            <tr>
                                <td className="Description Required">Address</td>
                                <td>
                                    <TextField value={guestForm.address} onChange={(e) => handleFormChange(e, "address")} className="CreateGuestInput" fullWidth required margin="normal" />
                                </td>
                            </tr>
                            <tr>
                                <td className="Description Required">City</td>
                                <td>
                                    <TextField value={guestForm.city} onChange={(e) => handleFormChange(e, "city")} className="CreateGuestInput" fullWidth required margin="normal" />
                                </td>
                            </tr>
                            <tr>
                                <td className="Description Required">Country</td>
                                <td>
                                    <ReactFlagsSelect
                                        searchable={true}
                                        defaultCountry={guestForm.country}
                                        onSelect={(countryCode) => setCountry(countryCode)}
                                        countries={countries}
                                        searchPlaceholder="Search for a country"
                                    />
                                </td>
                            </tr>
                            <tr style={regions.length > 0 ? { display: 'table-row' } : { display: 'none' }}>
                                <td className="Description Required">Region / State</td>
                                <td>
                                    <select value={guestForm.state} onChange={(e) => setRegion({ region: e.target.value })}>
                                        {
                                            regions.map((region, index) => {
                                                return (
                                                    <option key={index} value={region.id}>{region.value}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className="Description Required">Postal Code</td>
                                <td>
                                    <TextField value={guestForm.postalCode} onChange={(e) => handleFormChange(e, "postalCode")} className="CreateGuestInput" fullWidth required margin="normal" />
                                </td>
                            </tr>
                            <tr>
                                <td className="Description Required">Home Phone</td>
                                <td>
                                    <TextField value={guestForm.homePhone} onChange={(e) => handleFormChange(e, "homePhone")} className="CreateGuestInput" fullWidth required margin="normal" />
                                </td>
                            </tr>
                            <tr>
                                <td className="Description">Mobile Phone</td>
                                <td>
                                    <TextField value={guestForm.mobilePhone} onChange={(e) => handleFormChange(e, "mobilePhone")} className="CreateGuestInput" fullWidth margin="normal" />
                                </td>
                            </tr>
                            <tr>
                                <td className="Description">Work Phone</td>
                                <td>
                                    <TextField value={guestForm.workPhone} onChange={(e) => handleFormChange(e, "workPhone")} className="CreateGuestInput" fullWidth margin="normal" />
                                </td>
                            </tr>
                            <tr>
                                <td className="Description">Fax</td>
                                <td>
                                    <TextField value={guestForm.fax} onChange={(e) => handleFormChange(e, "fax")} className="CreateGuestInput" fullWidth margin="normal" />
                                </td>
                            </tr>
                            <tr>
                                <td className="Description Required">Email Address</td>
                                <td>
                                    <TextField value={guestForm.emailAddress} onChange={(e) => handleFormChange(e, "emailAddress")} className="CreateGuestInput" fullWidth required margin="normal" />
                                </td>
                            </tr>
                            <tr>
                                <td className="Description">Member Number</td>
                                <td>
                                    <TextField value={guestForm.memberNumber} onChange={(e) => handleFormChange(e, "memberNumber")} type="number" className="CreateGuestInput" fullWidth margin="normal" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        )
    }

    const handleRenderModalFooter = () => {
        return (
            <div className="ModalFooter">
                <Button className={props.env === "b2b" ? "Primary CTA" : "secondary"} onClick={() => handleSaveCreateGuest(false)}>
                    {guestForm.isEdit ? "Update Guest" : "Create Guest"}
                </Button>
                <Button className={props.env === "b2b" ? "Secondary CTA" : "secondary"} onClick={() => handleCloseModal()}>
                    Cancel
                </Button>
            </div>
        );
    }

    if (!countriesRetrieved) {
        console.log(props);
        setCountriesRetrieved(true);
        getCountries();
        if (props.forceModalSpawn === true) {
            setModalState(true);
        }
        if (props.userId) {
            handleEditUserModal(props.userId);
        }
    }

    if (props.forceModalSpawn === true && props.userId && forceModalSpawn === false) {
        console.log('x');
        setForceModalSpawn(true);
        handleEditUserModal(props.userId);
    }

    if (props.forceModalSpawn === false && forceModalSpawn === true) {
        console.log('y');
        setModalState(false);
        setForceModalSpawn(false);
    }

    return (
        <React.Fragment>
            <Button className={"AddButton " + props.buttonClass} onClick={() => setModalState(true)}>
                {
                    props.env === "b2b" ? (
                        <SpotlioIcon icon="misc-accordion-plus" />
                    ) : (
                        "Create New Guest"
                    )
                }
            </Button>
            {
                props.env === "b2b" ? (
                    <MaterialModal disablePortal={true} className="Modal" aria-labelledby="Create Guest Modal" aria-describedby="Modal to create new guests" open={isModalOpen} onClose={() => handleCloseModal()}>
                        <div className="InnerModal">
                        <h2>{guestForm.isEdit ? "Edit Guest" : "Create a New Guest"}</h2>
                            { handleRenderModalContent() }
                            { handleRenderModalFooter() }
                        </div>
                    </MaterialModal>
                ) : (
                    <B2CModal
                        open={isModalOpen}
                        title="Create a New Guest"
                        className="attachGuest Modal"
                        closeFn={() => handleCloseModal()}
                        footerContent={handleRenderModalFooter()}>
                        <div className="InnerModal">
                            { handleRenderModalContent() }
                        </div>
                    </B2CModal>
                )
            }
        </React.Fragment>
    );
}
