import React from 'react'

import Drawer from '@material-ui/core/Drawer';
import styled from 'styled-components';

import ThemeVars from '../Layout/Themes/themeVariables';
import { getSystemSettings } from '../../utils/SettingsRetriever';

import './index.scss';

const variables = ThemeVars(getSystemSettings());
const StyledDrawer = styled(Drawer)`
.MuiDrawer-paper{
    background-color: ${variables.drawer_background};
    .header{
        background-color: ${variables.drawer_background};
    }
}
&.categoriesMenu{
    .menu{
        .container{
            .option{
                background-color: ${variables.drawer_categoriesmenu_option_background};
                color: ${variables.drawer_categoriesmenu_option_color};
                outline-color: ${variables.drawer_categoriesmenu_option_border};
                svg{
                    color: ${variables.drawer_categoriesmenu_option_icon_color};
                    fill: ${variables.drawer_categoriesmenu_option_icon_color};
                }
                .label{
                    color: ${variables.drawer_categoriesmenu_option_label_color};
                }
                &:hover{
                    background-color: ${variables.drawer_categoriesmenu_option_hover_background};
                    svg{
                        color: ${variables.drawer_categoriesmenu_option_hover_icon_color};
                        fill: ${variables.drawer_categoriesmenu_option_hover_icon_color};
                    }
                    .label{
                        color: ${variables.drawer_categoriesmenu_option_hover_label_color};
                    }
                }
            }
        }
    }
    .bottommenu{
        background-color: ${variables.drawer_bottommenu_background};
        .options{
            .bottomoption{
                .optionText{
                    color: ${variables.drawer_bottommenu_option_label_color};
                }
                svg{
                    color: ${variables.drawer_bottommenu_option_icon_color};
                    fill: ${variables.drawer_bottommenu_option_icon_color};
                }
                &:hover{
                    .optionText{
                        color: ${variables.drawer_bottommenu_option_hover_label_color};
                    }
                    svg{
                        color: ${variables.drawer_bottommenu_option_hover_icon_color};
                        fill: ${variables.drawer_bottommenu_option_hover_icon_color};
                    }
                }
            }
        }
        .closemenu{
            color: ${variables.drawer_bottommenu_close_color};
        }
    }
}
&.summaryCartDrawer{
    .cartSection{
        background-color: ${variables.cart_background};
        .topBar{
            .title{
                color: ${variables.cart_header_title_color};
            }
        }

        .itinerary-id{
            color: ${variables.cart_header_itinerary_color};
            .save{
                color: ${variables.cart_header_itinerary_save_color};
            }
        }

        .products{
            .agrupation{
                .header{
                    background-color: ${variables.cart_group_background};
                    color: ${variables.cart_group_color};
                }
                .product{
                    background-color: ${variables.cart_product_background};
                    .multimedia{
                        background-color: ${variables.cart_product_photo_background};
                        .icon{
                            fill: ${variables.cart_product_photo_icon_color};
                        }
                    }
                    .content{
                        .supplierName{
                            color: ${variables.cart_product_supplier_color};
                        }
                        .productName{
                            color: ${variables.cart_product_product_color};
                        }
                        .date, .qty{
                            color: ${variables.cart_product_datequantity_color};
                        }
                    }
                    .sideinfo{
                        .remove{
                            background-color: ${variables.cart_product_remove_background};
                            color: ${variables.cart_product_remove_color};
                        }
                        .price{
                            .savings{
                                color: ${variables.cart_product_savings_color};
                            }
                        }
                        p{
                            color: ${variables.cart_product_selected_package_color};
                        }
                    }
                }
            }
        }

        .bottom{
            .upselling{
                background-color: ${variables.cart_upselling_background};
                h3{
                    &.topTitle{
                        color: ${variables.cart_upselling_top_title_color};
                    }
                    &.bottomTitle{
                        color: ${variables.cart_upselling_bottom_title_color};
                    }
                }
                .container{
                    button{
                        color: ${variables.cart_upselling_category_color};
                        svg{
                            fill: ${variables.cart_upselling_category_icon_color};
                        }
                        &:hover{
                            color: ${variables.cart_upselling_category_hover_color};
                            svg{
                                fill: ${variables.cart_upselling_category_icon_hover_color};
                            }
                        }
                    }
                }
            }
            .summary{
                .summaryName, .taxes{
                    color: ${variables.cart_summary_subtotal};
                    &.total{
                        background-color: ${variables.cart_summary_total_background};
                    }
                }
                .taxes{
                    color: ${variables.cart_summary_taxes_link_color};
                }
                .totalDueNow{
                    color: ${variables.cart_summary_subtotal};
                }
            }
        }
        .checkout{
            .goToCheckout{
                border-color: ${variables.checkout_buttons_cart_border};
                background: ${variables.checkout_buttons_cart_background};
                color: ${variables.checkout_buttons_cart_color};
                &:hover{
                    border-color: ${variables.checkout_buttons_cart_hover_border};
                    background: ${variables.checkout_buttons_cart_hover_background};
                    color: ${variables.checkout_buttons_cart_hover_color};
                }
            }
        }
    }
}
`;

export default ({ className, open, children, ...rest }) => {
    let isOpen = open;

    return(
    <StyledDrawer open={isOpen} className={"generalDrawer " + className} {...rest}>
        {children}
    </StyledDrawer>
    );
}