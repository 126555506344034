/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import Container from '@material-ui/core/Container';

import moment from 'moment';

import './index.scss';

export default ({ childProps }) => (
    <div className="socketWrapper">
        <Container className="container" maxWidth="lg">
            <div className="poweredBySpotlio">
                <span>Powered by</span>
                <a target="_blank" href="https://www.spotlio.com/">
                    <img alt="Powered By SPOTLIO" src="https://shopspotlio.spotlio.com/bundles/spotliowebappfront/spotlio_logo_footer.png" />
                </a>
            </div>
            <div className="buildVersion">
                <span>v{moment(parseInt(global.appVersion)).format('YYYYMMDDHHmmss')}</span>
            </div>
        </Container>
    </div>
);
