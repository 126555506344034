import React from 'react';

import * as Icon from 'react-feather';

import SpotlioIcon from '../../Icon';
import Currency from '../../Currency';
import DateFormat from '../../DateFormat';

import IntlMessage from '../../../LocaleEngine';

export default function Cart(props){
    const { index, item, agrupation } = props;

    return (
        <div key={"item"+index} className="product">
            <div className="multimedia" data-id={agrupation.id} data-supercategoryid={agrupation.supercategoryid} data-name={agrupation.name}>
                <SpotlioIcon icon={agrupation.icon} />
            </div>
            <div className="content">
                <div className="supplierName">{item.SupplierName}</div>
                <div className="productName">{item.ProductName}</div>
                <div className="date"><span><IntlMessage id="cart.labels.arrival_date" />: </span><DateFormat date={item.ArrivalDate} /> <IntlMessage id="cart.labels.after" /> {item.StartTime}</div>
                <div className="date"><span><IntlMessage id="cart.labels.departure_date" />: </span><DateFormat date={item.DepartureDate} /> <IntlMessage id="cart.labels.before" /> {item.EndTime}</div>
                <div className="date"><span><IntlMessage id="cart.labels.adults" />: </span>{item.AdultCount}</div>
                <div className="date"><span><IntlMessage id="cart.labels.children" />: </span>{item.ChildCount}</div>
            </div>
            <div className="sideinfo">
                <div className="price">
                    <Currency float={true} value={item.Price}/>
                    {item.PackageName ?
                    <React.Fragment>
                        {item.OriginalPrice - item.Price !== 0 ? <div className="savings"><IntlMessage id="cart.labels.savings" />: <Currency float={true} onlyOriginal={true} value={parseFloat(item.OriginalPrice).toFixed(2) - parseFloat(item.Price).toFixed(2)}/></div> : null}
                        <p>{item.PackageName}</p>
                    </React.Fragment>
                    : null}
                </div>
                <div onClick={() => props.deleteItem(item, true)} className="remove">
                    <Icon.Trash2  color={"white"} />
                    <span><IntlMessage id="cart.remove_item.buttons.remove" /></span>
                </div>
            </div>
        </div>
    )
}
