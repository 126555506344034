import React, { Component } from 'react';

import history from '../../../history';
import { getSystemSettings } from '../../../utils/SettingsRetriever';
import IntlMessage from '../../../LocaleEngine';

import Container from '@material-ui/core/Container';
import Drawer from '../../Drawer';
import SpotlioIcon from '../../Icon';

import Cart from '../../../containers/Cart';

export default class TabGenerator extends Component{
    constructor(props){
        super(props);
        this.state = {
            selectedTab: -1,
            modalCart: false,
        }
    }

    handlePush = (path, idx) => {
        history.push(path);
        this.setState({selectedTab:idx})
    }

    handleActionMobile = (urlPath, idx) => {
        this.handlePush(urlPath, idx);
        this.props.toggleMenu()
    }

    openSideCart = () => {
        this.setState({modalCart: !this.state.modalCart});
    }

    render(){
        let items = [];
        let mobile = [];
        const system = getSystemSettings();
        const url = window.location.pathname;

        if (system.hasOwnProperty('categories')) {
            system.categories.forEach((val, idx) => {
                let urlPath = val.href[0];
                if (val.href.indexOf(url) > -1 || (url === '/' && val.href.indexOf(system.app.defaultPage) >= 0)) {
                    if (this.state.selectedTab !== idx) {
                        this.setState({selectedTab:idx});
                    }
                }
                if (this.state.selectedTab === -1) {
                    val.href.forEach(jdx => {
                        if (url.substring(0, jdx.length) === jdx) {
                            if (this.state.selectedTab !== idx) {
                                this.setState({selectedTab:idx});
                            }
                        }
                    })
                }

                let icon = 'sp2-mountains';
                if(val.icon !== ''){
                    icon = val.icon;
                }

                let visible = true;
                if(val.hide){
                    visible = false;
                }

                if(this.props.upselling){
                    if(val.hide_upselling){
                        visible = false;
                    }
                }

                if(visible){
                    const title = <IntlMessage id={"skin.global.tabs." + val.code} />
                    items.push(
                        <button key={idx} onClick={() => this.handlePush(urlPath, idx)} title={title} className={idx === this.state.selectedTab ? "active" : ""}>
                            <SpotlioIcon icon={icon} />
                            <span className="label">{title}</span>
                        </button>
                    )
                    mobile.push(
                        <div key={idx} onClick={() => (this.handleActionMobile(urlPath, idx))} className="option"><SpotlioIcon icon={icon} /><span className="categoryText">{title}</span></div>
                    )
                }
            })
        } else {
            this.forceUpdate();
        }

        return (
            <Container className="container" maxWidth="lg">
                {this.props.isMobile ?
                    mobile
                    :
                    items
                }
                {!(this.props.upselling) ?
                <div className="CartButtonWrapper">
                    <button className="cartButton" onClick={() => this.openSideCart()}>
                        <span className="label"><IntlMessage id="cart.general.title" /></span>
                    </button>
                    <Drawer onBackdropClick={() => this.openSideCart()} variant={'temporary'} anchor={'right'} open={this.state.modalCart} className="summaryCartDrawer">
                        <Cart />
                    </Drawer>
                </div>
                : null}
            </Container>
        )
    }
}


