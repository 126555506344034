/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import * as Icon from 'react-feather';

import { getSystemSettings } from '../../../utils/SettingsRetriever';

import SpotlioIcon from '../../Icon';
import TabGenerator from './TabGenerator';
import Drawer from '../../Drawer';

import IntlMessage from '../../../LocaleEngine';

import './index.scss';

export default (props) => {
    const configuration = getSystemSettings();

    const toggleMenu = () => {
        props.toggleMenu()
    }

    let headerImage = '';
    if(! !configuration.assets.mobile.background) headerImage = configuration.assets.mobile.background;

    let showLanguage = false;
    if(! !configuration.head.multilanguage) showLanguage = configuration.head.multilanguage;

    let showCurrency = false;
    if(! !configuration.head.multicurrency) showCurrency = configuration.head.multicurrency;

    let showProfile = false;
    if(! !configuration.head.profile) showProfile = configuration.head.profile;

    return (
        <Drawer open={props.menu} className="categoriesMenu">
            {
                headerImage !== '' ?
                <div className="header">
                    <img alt={configuration.head.title} src={headerImage} />
                </div> : ''
            }
            <div className="menu">
                <TabGenerator toggleMenu={toggleMenu.bind(this)} isMobile={true} cart={false}/>
            </div>
            <div className="bottommenu">
                <div className="options">
                    {
                        showCurrency ?
                        <div className="bottomoption">
                            <SpotlioIcon icon="sp2-snow-flake" />
                            <span className="optionText"><IntlMessage id="currency.buttons.currency" /></span>
                        </div>
                        : ''
                    }
                    {
                        showLanguage ?
                        <div className="bottomoption">
                            <SpotlioIcon icon="sp2-snow-flake" />
                            <span className="optionText"><IntlMessage id="header.info.language" /></span>
                        </div>
                        : ''
                    }
                    {
                        showProfile ?
                        <div className="bottomoption">
                            <SpotlioIcon icon="sp2-snow-flake" />
                            <span className="optionText"><IntlMessage id="profile.title.profile" /></span>
                        </div>
                        : ''
                    }
                </div>
                <Icon.XSquare onClick={()=>props.toggleMenu(false)} size={28} className="closemenu"></Icon.XSquare>
            </div>
        </Drawer>
    );
}
