import React from 'react'

import { getSystemSettings, getUserSettings, getCurrencyDatabase } from '../../utils/SettingsRetriever';

import './index.scss';


export default class Currency extends React.Component {
    constructor(props) {
        super(props);
        this.currencySettings = getSystemSettings().app.currency;
        this.selectedCurrencyIndex = getUserSettings().currencies.selected;
    }

    convertCurrency = (ammount, destination) => {
        const CurrencyDB = getCurrencyDatabase();
        return (ammount * CurrencyDB.rates[destination]).toFixed(2);
    }

    render() {
        const currencyIndex = this.currencySettings.default;
        const currency
            = (this.currencySettings.displayMethod === "full" ? this.currencySettings.list[currencyIndex].base : "")
            + " " + this.currencySettings.list[currencyIndex].symbol;

        let { value = 0, float = false } = this.props;
        value = parseFloat(value);
        let convertedValue = this.convertCurrency(value, this.currencySettings.list[this.selectedCurrencyIndex].base);

        if (value === 0 && this.props.make) {
            return (
                <React.Fragment>
                    <div className="priceWrap">
                        <span className="price free">SOLD OUT</span>
                    </div>
                </React.Fragment>
            );
        }
        if (value === 0 && this.props.loadingSearch) {
            return (
                <React.Fragment>
                    <div className="priceWrap">
                        <span className="price free">Searching...</span>
                    </div>
                </React.Fragment>
            );
        }

        if (value === 0) {
            return (
                <React.Fragment>
                    <div className="priceWrap">
                        <span className="price free">FREE</span>
                    </div>
                </React.Fragment>
            );
        }

        if (float){
            value = (
                <React.Fragment>
                    <span>{value.toFixed(2).split('.')[0]}</span><span className="smaller" >.{value.toFixed(2).split('.')[1]}</span>
                </React.Fragment>
            )
        } else {
            value = parseInt(value);
        }

        return (
            <React.Fragment>
                <div className="priceWrap">
                    <span className="currency">{currency}</span>
                    <span className="price">{value}</span>
                    {
                        (this.currencySettings.default !== this.selectedCurrencyIndex && value !== 0 && !this.props.onlyOriginal) && (
                            <div className="converted">
                                <span className="currency converted">
                                    {(this.currencySettings.displayMethod === "full" ? this.currencySettings.list[this.selectedCurrencyIndex].base : "") + " " + this.currencySettings.list[this.selectedCurrencyIndex].symbol}
                                </span>
                                <span className="price converted">
                                    {convertedValue}
                                </span>
                            </div>
                        )
                    }
                </div>
            </React.Fragment>
        );
    }

};
