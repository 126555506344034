import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { getSystemSettings, getCustomerConfig } from '../../utils/SettingsRetriever';
let SystemSettings = getSystemSettings();
let CustomerConfig = getCustomerConfig();

function Seo({ description, lang, meta, keywords, title, author, system, config }) {
    // TODO: change favicons for customer-defined ones
    if (SystemSettings === false) {
        SystemSettings = system;
    }
    if (CustomerConfig === false) {
        CustomerConfig = config;
    }
    return (
        <Helmet
            titleTemplate={"%s - " + SystemSettings.head.title || ''}
            htmlAttributes={{
                lang,
            }}
            title={title}
            link={[
                {
                    rel: `apple-touch-icon`,
                    sizes: `180x180`,
                    href: SystemSettings.assets.favicons.apple_touch_icon_180x180
                }, {
                    rel: `apple-touch-icon`,
                    sizes: `152x152`,
                    href: SystemSettings.assets.favicons.apple_touch_icon_180x180
                }, {
                    rel: `apple-touch-icon`,
                    sizes: `144x144`,
                    href: SystemSettings.assets.favicons.apple_touch_icon_144x144
                }, {
                    rel: `apple-touch-icon`,
                    sizes: `120x120`,
                    href: SystemSettings.assets.favicons.apple_touch_icon_120x120
                }, {
                    rel: `apple-touch-icon`,
                    sizes: `114x114`,
                    href: SystemSettings.assets.favicons.apple_touch_icon_114x114
                }, {
                    rel: `apple-touch-icon`,
                    sizes: `76x76`,
                    href: SystemSettings.assets.favicons.apple_touch_icon_76x76
                }, {
                    rel: `apple-touch-icon`,
                    sizes: `72x72`,
                    href: SystemSettings.assets.favicons.apple_touch_icon_72x72
                }, {
                    rel: `apple-touch-icon`,
                    sizes: `57x57`,
                    href: SystemSettings.assets.favicons.apple_touch_icon_57x57
                }, {
                    rel: `apple-touch-icon`,
                    href: SystemSettings.assets.favicons.apple_touch_icon
                }, {
                    rel: `shortcut icon`,
                    href: SystemSettings.assets.favicon
                }
            ]}
            meta={[
                {
                    name: `application-name`,
                    content: CustomerConfig.config.title
                }, {
                    name: `description`,
                    content: description,
                }, {
                    property: `og:title`,
                    content: title,
                }, {
                    property: `og:description`,
                    content: description,
                }, {
                    property: `og:type`,
                    content: `website`,
                }, {
                    name: `twitter:card`,
                    content: `summary`,
                }, {
                    name: `twitter:creator`,
                    content: author,
                }, {
                    name: `twitter:title`,
                    content: title,
                }, {
                    name: `twitter:description`,
                    content: description,
                },
            ].concat(keywords.length > 0
                ? {
                    name: `keywords`,
                    content: keywords.join(`, `),
                }
                : []
            ).concat(meta)}
        />
    )
}

Seo.defaultProps = {
    lang: `en`,
    meta: [],
    keywords: [],
}

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.array,
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string.isRequired,
    author: PropTypes.string
}

export default Seo;
