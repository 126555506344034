import styled from 'styled-components';

import _variables from '../themeVariables';

export default (system) => {
    const variables = _variables(system);

    return styled.div`
        background-color: ${variables.general_background_color};
        .topbarWrapper{
            background-color: ${variables.topbar_background_color};
            .container{
                .options{
                    .message{
                        color: ${variables.topbar_message_color};
                        bold, a{
                            color: ${variables.topbar_message_bold_color};
                        }
                    }
                    .links{
                        ul li a{
                            color: ${variables.topbar_message_link_color};
                        }
                    }
                    .HeaderWrapper{
                        .SwitchesWrapper{
                            .TopBarSwitches{
                                background-color: ${variables.b2b_topbar_switches_background};
                                button{
                                    color: ${variables.b2b_topbar_switches_nonselected_color};
                                    background-color: ${variables.b2b_topbar_switches_nonselected_background};
                                    &.selected{
                                        background-color: ${variables.b2b_topbar_switches_selected_background};
                                        color: ${variables.b2b_topbar_switches_selected_color};
                                    }
                                }
                            }
                        }
                        .tatoButtons{
                            .tatoButton{
                                .avatar{
                                    background-color: ${variables.b2b_topbar_buttons_background};
                                    color: ${variables.b2b_topbar_buttons_color};
                                    svg{
                                        fill: ${variables.b2b_topbar_buttons_color};
                                    }
                                }
                            }
                        }
                    }
                }
            }
            @media (max-width: 991px){
                background-color: ${variables.topbar_mobile_background};
                .menu{
                    svg{
                        fill: ${variables.topbar_menu_icon};
                    }
                }
                .cart{
                    .has-badge{
                        color: ${variables.topbar_badge_color};
                    }
                    svg{
                        fill: ${variables.topbar_cart_icon};
                    }
                }
            }
        }
        .navWrapper{
            .container{
                button{
                    background-color: ${variables.navbar_tab_background};
                    color: ${variables.navbar_tab_color};
                    border-color: ${variables.navbar_tab_border};
                    svg{
                        fill: ${variables.navbar_tab_color};
                    }
                    &.active,&.active:hover{
                        background-color: ${variables.navbar_tab_active_background};
                        color: ${variables.navbar_tab_active_color};
                        svg{
                            fill: ${variables.navbar_tab_active_color};
                        }
                    }
                    &:hover{
                        background-color: ${variables.navbar_tab_hover_background};
                        color: ${variables.navbar_tab_hover_color};
                        svg{
                            fill: ${variables.navbar_tab_hover_color};
                        }
                    }
                    &.cartButton{
                        background-color: ${variables.navbar_tab_shoppingcart_background};
                        color: ${variables.navbar_tab_shoppingcart_color};
                    }
                }
            }
        }
        .searchWrapper{
            .container{
                .search{
                    background-color: ${variables.searchbar_background};
                    color: ${variables.searchbar_color};
                    .searchBox{
                        .SingleDatePickerInput, .DateRangePickerInput{
                            border-color: ${variables.searchbar_input_border};
                            .DateRangePickerInput_arrow_svg{
                                fill: ${variables.searchbar_input_color};
                            }
                            .DateInput{
                                background: ${variables.searchbar_input_background};
                                .DateInput_input{
                                    background: ${variables.searchbar_input_background};
                                    color: ${variables.searchbar_input_color};
                                }
                            }
                        }
                        .searchAction{
                            .searchButton{
                                background: ${variables.searchbar_button_background};
                                color: ${variables.searchbar_button_color};
                            }
                        }
                    }
                }
            }
            .mobilesearch{
                .searchform, .filter, .listMenuSortby{
                    background-color: ${variables.mobilesearch_background};;
                    color: ${variables.mobilesearch_color};
                }
                .searchform, .filter{
                    border-right-color: rgba(0, 0, 0, 0.2);
                }
                .filter, .listMenuSortby{
                    border-left-color: rgba(255, 255, 255, 0.2);
                }
            }
        }
        .lodgingSection{
            .content{
                .results{
                    background-color: ${variables.lodginglist_background};
                }
            }
            .sidebar{
                .filter{
                    .head{
                        background-color: ${variables.lodginglist_filter_head_background};
                        color: ${variables.lodginglist_filter_head_color};
                    }
                    .content{
                        background-color: ${variables.lodginglist_filter_content_background};
                    }
                    &.byPrice{
                        .content{
                            .input-range{
                                .input-range__label{
                                    color: ${variables.lodginglist_filter_content_slider_label_color};
                                }
                                .input-range__track{
                                    background: ${variables.lodginglist_filter_content_slider_track_color};
                                }
                                .input-range__track--active{
                                    background: ${variables.lodginglist_filter_content_slider_track_active_color};
                                }
                                .input-range__slider{
                                    background: ${variables.lodginglist_filter_content_slider_track_point_color};
                                    border-color: ${variables.lodginglist_filter_content_slider_track_point_color};
                                }
                            }
                        }
                    }
                }
            }
        }
        .activitiesSection, .merchandiseSection{
            .content{
                .results{
                    background-color: ${variables.activitylist_background};
                    .filters{
                        .filter{
                            border: 1px solid #333333;
                            background-color:#f3f2f3;
                        }
                    }
                }
            }
        }
        .item{
            &.lodging{
                border-color: ${variables.item_lodging_border_color};
                .content{
                    .titleWrap{
                        .title{
                            color: ${variables.item_lodging_title_color};
                        }
                    }
                    .descriptionWrap{
                        .description{
                            color: ${variables.item_lodging_description_color};
                            .toggler{
                                color: ${variables.item_lodging_description_link_color};
                            }
                        }
                    }
                    .locationWrap{
                        svg{
                            color: ${variables.item_lodging_village_icon_color};
                        }
                        span{
                            color: ${variables.item_lodging_village_text_color};
                        }
                    }
                }
                .pricing{
                    .main-cta{
                        .primary{
                            background: ${variables.item_lodging_primary_cta_background};
                            color: ${variables.item_lodging_primary_cta_color};
                        }
                    }
                }
            }
            &.lodgingDetails{
                border-color: ${variables.item_lodging_border_color};
                .tabWrapper{
                    .tabBar{
                        color: ${variables.tabbar_color};
                        .tabsMenu{
                            .tabOption{
                                &.Mui-selected{
                                    background-color: ${variables.tabbar_selected_background};
                                    color: ${variables.tabbar_selected_color};
                                    border-color: ${variables.tabbar_selected_border};
                                }
                            }
                        }
                    }
                    .tabPanes{
                        background-color: ${variables.tabbar_pane_background};
                        border-color: ${variables.tabbar_pane_border};
                        .tabPane{
                            border-color: ${variables.tabbar_pane_border};
                            .content{
                                .titleWrap{
                                    .title{
                                        color: ${variables.item_lodging_unit_title_color};
                                    }
                                }
                                .descriptionWrap{
                                    .description{
                                        color: ${variables.item_lodging_unit_description_color};
                                        .toggler{
                                            color: ${variables.item_lodging_unit_description_link_color};
                                        }
                                    }
                                }
                            }
                            .pricing{
                                .main-cta{
                                    .primary{
                                        background: ${variables.item_lodging_unit_primary_cta_background};
                                        color: ${variables.item_lodging_unit_primary_cta_color};
                                    }
                                }
                            }
                            .compact{
                                .title{
                                    color: ${variables.item_lodging_unit_title_color};
                                }
                                .savings{
                                    color: ${variables.item_lodging_unit_savings_color};
                                }
                                button{
                                    background: ${variables.item_lodging_unit_primary_cta_background};
                                    color: ${variables.item_lodging_unit_primary_cta_color};
                                }
                            }
                        }
                    }
                }
            }
            .roomCell .item.roomDetails .tabWrapperRoom .tabPanes .tabPane .packages .package .content .booknow .book, 
            .roomCell .item.roomDetails .tabWrapperRoom .tabPanes .tabPane .packages .room .content .booknow .book{
                background: ${variables.item_lodging_unit_selectoffer_cta_background};
                color: ${variables.item_lodging_unit_selectoffer_cta_color};
                border-color: ${variables.item_lodging_unit_selectoffer_cta_border_color};
            }

            
            .roomCell .item.roomDetails .tabWrapperRoom .tabPanes .tabPane .rooms .room .content .booknow .book,
            .roomCell .item.roomDetails .tabWrapperRoom .tabPanes .tabPane .rooms .package .content .booknow .book{
                background: ${variables.item_lodging_unit_booknow_cta_background};
                color: ${variables.item_lodging_unit_booknow_cta_color};
                border-color: ${variables.item_lodging_unit_booknow_cta_border_color};
            }

            &.activities, &.merchandise{
                border-color: ${variables.item_activities_border_color};
                .content{
                    .titleWrap{
                        .title{
                            color: ${variables.item_activities_title_color};
                        }
                    }
                    .descriptionWrap{
                        .description{
                            color: ${variables.item_activities_description_color};
                            .toggler{
                                color: ${variables.item_activities_description_link_color};
                            }
                        }
                    }
                }
                .pricing{
                    .main-cta{
                        .primary{
                            background: ${variables.item_activities_primary_cta_background};
                            color: ${variables.item_activities_primary_cta_color};
                        }
                    }
                }
            }
            &.activitiesDetails, &.merchandiseDetails{
                border-color: ${variables.item_activities_border_color};
                .tabWrapper{
                    .tabBar{
                        color: ${variables.tabbar_color};
                        .tabsMenu{
                            .tabOption{
                                &.Mui-selected{
                                    background-color: ${variables.tabbar_selected_background};
                                    color: ${variables.tabbar_selected_color};
                                    border-color: ${variables.tabbar_selected_border};
                                }
                            }
                        }
                    }
                    .tabPanes{
                        background-color: ${variables.tabbar_pane_background};
                        border-color: ${variables.tabbar_pane_border};
                        .tabPane{
                            border-color: ${variables.tabbar_pane_border};
                            .content{
                                .titleWrap{
                                    .title{
                                        color: ${variables.item_activities_unit_title_color};
                                    }
                                }
                                .descriptionWrap{
                                    .description{
                                        color: ${variables.item_activities_unit_description_color};
                                        .toggler{
                                            color: ${variables.item_activities_unit_description_link_color};
                                        }
                                    }
                                }
                            }
                            .pricing{
                                .main-cta{
                                    .primary{
                                        background: ${variables.item_activities_unit_primary_cta_background};
                                        color: ${variables.item_activities_unit_primary_cta_color};
                                    }
                                }
                            }
                            .options {
                                .headers{
                                    .title, .amount, .price{
                                        color: ${variables.item_activities_unit_headers_color};
                                    }
                                }
                                .values{
                                    .product, .mobileProduct{
                                        .groupTitle .cellTitle{
                                            color: ${variables.item_activities_unit_title_color};
                                        }
                                        .book{
                                            background: ${variables.item_activities_unit_primary_cta_background};
                                            color: ${variables.item_activities_unit_primary_cta_color};
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .summaryCart{
            .topBar{
                color: ${variables.summarycart_general_color};
                background-color: ${variables.summarycart_general_background};
            }
            .products{
                background-color: ${variables.summarycart_product_background};
                .agrupation{
                    .header{
                        background-color: ${variables.summarycart_group_background};
                        color: ${variables.summarycart_group_color};
                    }
                }
                .product{
                    border-color: ${variables.summarycart_product_border};
                    .item{
                        .content{
                            .names{
                                .supplierName{
                                    color: ${variables.summarycart_product_supplier_color};
                                }
                                .productName{
                                    color: ${variables.summarycart_product_product_color};
                                }
                            }
                            .options{
                                svg{
                                    background-color: ${variables.summarycart_remove_background};
                                    color: ${variables.summarycart_remove_color};
                                }
                            }
                        }
                        .dateqty{
                            .dates, .guests{
                                color: ${variables.summarycart_product_dateguest_color};
                                span{
                                    color: ${variables.summarycart_product_dateguest_span_color};
                                }
                            }
                        }
                    }
                }
            }
            .summary{
                color: ${variables.summarycart_general_color};
                background-color: ${variables.summarycart_general_background};
            }
            .footer{
                background: ${variables.summarycart_cta_background};
                color: ${variables.summarycart_cta_color};
            }
        }
        .packagesSection{
            .content{
                .results{
                    background-color: ${variables.packages_list_background};
                }
                .list{
                    .item{
                        &.package{
                            .content{
                                .titleWrap{
                                    color: ${variables.packages_item_title};
                                }
                                .descriptionWrap{
                                    .description{
                                        color: ${variables.packages_item_description};
                                        .toggler{
                                            color: ${variables.packages_item_description_link_color};
                                        }
                                    }
                                }
                            }
                            .pricing{
                                .main-cta{
                                    .primary{
                                        background: ${variables.packages_item_cta_background};
                                        color: ${variables.packages_item_cta_color};
                                        a{
                                            color: ${variables.packages_item_cta_color};
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .packageSection{
            .content{
                .results{
                    background-color: ${variables.package_background};
                }
                .list{
                    .package{
                        .header{
                            color: ${variables.package_title_color};
                        }
                        .information{
                            color: ${variables.package_description_color};
                            .toggler{
                                color: ${variables.package_description_link_color};
                            }
                        }
                        .components{
                            .package-navbar{
                                background-color: ${variables.package_components_navbar_background};
                                .required{
                                    border-top-color: ${variables.package_components_navbar_required_border};
                                }
                                .optional{
                                    border-top-color: ${variables.package_components_navbar_optional_border};
                                }
                                .MuiTabs-scrollButtons{
                                    background-color: ${variables.package_components_navbar_scrollbuttons_background};
                                    border-color: ${variables.package_components_navbar_scrollbuttons_border};
                                }
                                .MuiTab-textColorPrimary{
                                    background-color: ${variables.package_components_navbar_tab_background};
                                    color: ${variables.package_components_navbar_tab_color};
                                    border-left-color: ${variables.package_components_navbar_tab_border_left};
                                    border-right-color: ${variables.package_components_navbar_tab_border_right};
                                }
                                .Mui-selected{
                                    color: ${variables.package_components_navbar_tab_active_color};
                                    background-color: ${variables.package_components_navbar_tab_active_background};
                                    svg{
                                        color: ${variables.package_components_navbar_tab_active_icon_color};
                                        background-color: ${variables.package_components_navbar_tab_active_icon_background};
                                    }
                                }
                            }
                            .tabPanes{
                                border-color: ${variables.package_component_pane_border};
                                .component{
                                    .dates{
                                        .selectedDate{
                                            color: ${variables.package_component_dates_selected_color};
                                        }
                                        .availableDate{}
                                    }
                                }
                            }
                        }
                    }
                    .aside{
                        .searchBox{
                            .searchButton{
                                background: ${variables.package_aside_cta_background};
                                color: ${variables.package_aside_cta_color};
                            }
                        }
                    }
                }
            }
        }
        .cartSection{
            background-color: ${variables.cart_background};
            .topBar{
                .title{
                    color: ${variables.cart_header_title_color};
                }
            }

            .itinerary-id{
                color: ${variables.cart_header_itinerary_color};
                .save{
                    color: ${variables.cart_header_itinerary_save_color};
                }
            }

            .products{
                .agrupation{
                    .header{
                        background-color: ${variables.cart_group_background};
                        color: ${variables.cart_group_color};
                    }
                    .product{
                        background-color: ${variables.cart_product_background};
                        .multimedia{
                            background-color: ${variables.cart_product_photo_background};
                            .icon{
                                fill: ${variables.cart_product_photo_icon_color};
                            }
                        }
                        .content{
                            .supplierName{
                                color: ${variables.cart_product_supplier_color};
                            }
                            .productName{
                                color: ${variables.cart_product_product_color};
                            }
                            .date, .qty{
                                color: ${variables.cart_product_datequantity_color};
                            }
                        }
                        .sideinfo{
                            .remove{
                                background-color: ${variables.cart_product_remove_background};
                                color: ${variables.cart_product_remove_color};
                            }
                            .price{
                                .savings{
                                    color: ${variables.cart_product_savings_color};
                                }
                            }
                            p{
                                color: ${variables.cart_product_selected_package_color};
                            }
                        }
                    }
                }
            }

            .bottom{
                .upselling{
                    background-color: ${variables.cart_upselling_background};
                    h3{
                        &.topTitle{
                            color: ${variables.cart_upselling_top_title_color};
                        }
                        &.bottomTitle{
                            color: ${variables.cart_upselling_bottom_title_color};
                        }
                    }
                    .container{
                        button{
                            color: ${variables.cart_upselling_category_color};
                            svg{
                                fill: ${variables.cart_upselling_category_icon_color};
                            }
                            &:hover{
                                color: ${variables.cart_upselling_category_hover_color};
                                svg{
                                    fill: ${variables.cart_upselling_category_icon_hover_color};
                                }
                            }
                        }
                    }
                }
                .summary{
                    .summaryName, .taxes{
                        color: ${variables.cart_summary_subtotal};
                        &.total{
                            background-color: ${variables.cart_summary_total_background};
                        }
                    }
                    .taxes{
                        color: ${variables.cart_summary_taxes_link_color};
                    }
                    .totalDueNow{
                        color: ${variables.cart_summary_subtotal};
                    }
                }
            }
            .checkout{
                .goToCheckout{
                    border-color: ${variables.checkout_buttons_cart_border};
                    background: ${variables.checkout_buttons_cart_background};
                    color: ${variables.checkout_buttons_cart_color};
                    &:hover{
                        border-color: ${variables.checkout_buttons_cart_hover_border};
                        background: ${variables.checkout_buttons_cart_hover_background};
                        color: ${variables.checkout_buttons_cart_hover_color};
                    }
                }
            }
        }
        .suppliersSection{
            .content{
                .results{
                    .list{
                        .item{
                            .pricing{
                                .main-cta{
                                    a{
                                        color: ${variables.suppliers_link_color};
                                        &:hover{
                                            color: ${variables.suppliers_link_hover_color};
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .supplierSection{
            .content{
                .header{
                    .title-address{
                        .title{
                            color: ${variables.supplier_title_color};
                        }
                        .address{
                            color: ${variables.supplier_address_color};
                            span{
                                color: ${variables.supplier_address_link_color};
                            }
                        }
                    }
                }
            }
            .sidebar{
                .searchBox{
                    .searchButton{
                        background: ${variables.supplier_aside_button_background};
                        border-color: ${variables.supplier_aside_button_border};
                        color: ${variables.supplier_aside_button_color};
                    }
                }
            }
        }
        .generalModal{
            background-color: ${variables.modal_background};
            .header{
                background-color: ${variables.modal_header_background};
                color: ${variables.modal_header_color};
                svg{
                    color: ${variables.modal_header_icon_color};
                }
            }
            .footer{
                background-color: ${variables.modal_footer_background};
                .primary {
                    color: ${variables.modal_footer_cta_primary_color};
                    background: ${variables.modal_footer_cta_primary_background};
                    a{
                    color: ${variables.modal_footer_cta_primary_color};
                    }
                }
                .secondary {
                    color: ${variables.modal_footer_cta_secondary_color};
                    background-color: ${variables.modal_footer_cta_secondary_background};
                }
            }
        }
        .mobileCartSection{
            .checkout{
                button{
                    background-color: ${variables.cart_mobile_bottom_cta_background};
                    color: ${variables.cart_mobile_bottom_cta_color};
                }
            }
        }
        .numericStepper{
            button{
                &.primary{
                    border-color: ${variables.stepper_border};
                    color: ${variables.stepper_color};
                    background-color: ${variables.stepper_background};
                }
            }
            .Dropdown, .Dropdown:before, .Dropdown:after {
                color: ${variables.stepper_dropdown_color};
                background-color: ${variables.stepper_dropdown_background};
                #select-ammount{
                    color: ${variables.stepper_dropdown_color};
                    background-color: ${variables.stepper_dropdown_background};
                }
            }
            .bigDropDown{
                color: ${variables.stepper_dropdown_color};
                background-color: ${variables.stepper_dropdown_background};
                border-color: ${variables.stepper_dropdown_border};
                svg{
                    fill: ${variables.stepper_dropdown_color};
                }
            }
        }
        .liftTicketsSection{
            .SingleDatePicker .SingleDatePicker_picker .DayPicker > div > div:first-child .DayPicker_weekHeaders__horizontal .DayPicker_weekHeader ul{
                background-color: ${variables.yieldview_calendar_head_background};
                color: ${variables.yieldview_calendar_head_color};
            }
        }
        .liftTicketDaySection{
            .options .values .product .book{
                background-color: ${variables.yieldview_product_cta_primary_background};
                color: ${variables.yieldview_product_cta_primary_color};
            }
        }
        .footerWrapper{
            background-color: ${variables.footer_background};
            .container{
                .column{
                    .links{
                        ul{
                            li{
                                a{
                                    color: ${variables.footer_link_color};
                                    &:hover{
                                        color: ${variables.footer_link_hover_color};
                                    }
                                }
                            }
                        }
                    }
                    .title{
                        color: ${variables.footer_title_color};
                    }
                    .text{
                        color: ${variables.footer_text_color};
                        .title{
                            color: ${variables.footer_title_color};
                        }
                        strong{
                            color: ${variables.footer_bold_color};
                        }
                        a{
                            color: ${variables.footer_link_color};
                            &:hover{
                                color: ${variables.footer_link_hover_color};
                            }
                        }
                    }
                }
            }
        }
        .socketWrapper{
            background-color: ${variables.socket_background};
            .container{
                .poweredBySpotlio{
                    span{
                        color: ${variables.socket_color};
                    }
                }
            }
        }
    `;
}