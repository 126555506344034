import React from 'react';

import * as Icon from 'react-feather';

import SpotlioIcon from '../../Icon';
import Currency from '../../Currency';
import DateFormat from '../../DateFormat';

import IntlMessage from '../../../LocaleEngine';

export default function Cart(props){
    const { index, item, agrupation } = props;

    return (
        <div key={"item"+index} className="product">
            <div className="multimedia" data-id={agrupation.id} data-supercategoryid={agrupation.supercategoryid} data-name={agrupation.name}>
                <SpotlioIcon icon={agrupation.icon} />
            </div>
            <div className="content">
                <div className="supplierName">{item.SupplierName}</div>
                <div className="productName">{item.ProductName}</div>
                <div className="date"><span><IntlMessage id="activities.cell.option.head.date" />: </span><DateFormat date={item.ArrivalDate} /></div>
                <div className="qty"><span><IntlMessage id="activities.cell.option.head.qty" />: </span>{item.Quantity}</div>
            </div>
            <div className="sideinfo">
                <div className="price">
                    <Currency float={true} value={item.Price}/>
                    {item.PackageName ?
                    <React.Fragment>
                        {item.OriginalPrice - item.Price !== 0 ? <div className="savings"><IntlMessage id="activities.cart.lodge.savings" />: <Currency onlyOriginal={true} float={true} value={parseFloat(item.OriginalPrice).toFixed(2) - parseFloat(item.Price).toFixed(2)}/></div> : null}
                        <p>{item.PackageName}</p>
                    </React.Fragment>
                    : null}
                </div>
                <div onClick={() => props.deleteItem(item, true)} className="remove">
                    <Icon.Trash2  color={"white"} />
                    <span><IntlMessage id="cart.remove_item.buttons.remove" /></span>
                </div>
            </div>

        </div>
    )
}
