/* eslint-disable react/jsx-no-target-blank */
import React from 'react'

import { getSelectedPromocode } from '../../../utils/SettingsRetriever';

import TabGenerator from './TabGenerator';

import './index.scss';



export default () => {
    return (
        <React.Fragment>
            <nav className="navWrapper">
               {!getSelectedPromocode() ?  <TabGenerator cart={true}/> : null}
            </nav>
        </React.Fragment>
    );
}
