const getCookie = (name) => {
    let ret = false;
    const cookies = decodeURIComponent(document.cookie).split(";");
    cookies.forEach((val, idx) => {
        const cookie = val.split("=");
        if (name === cookie[0].replace(/\s/g, '')) {
            ret = Buffer.from(cookie[1], 'base64').toString('utf8');
        }
    })
    return ret;
}

const setCookie = (name, content, expiration) => {
    if (expiration instanceof Date) {
        const encodedContent = Buffer.from(content).toString('base64');
        document.cookie = name + "=" + encodedContent + ";expires=" + expiration.toUTCString() + ";path=/";
        return true;
    } else {
        throw Error('expiration must be a valid Date object!');
    }
}

const deleteCookie = (name) => {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
}

export {
    getCookie,
    setCookie,
    deleteCookie
}
