import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// https://material-ui.com/customization/default-theme/
export default responsiveFontSizes(createMuiTheme({
    typography: {
        fontFamily: [
            '"Nunito"',
            '"Open Sans"',
            '"Roboto"',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 700
    },
}));