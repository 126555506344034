import React from 'react';

import Currency from '../Currency';

import { getSessionId, getSystemSettings } from '../../utils/SettingsRetriever';
import SalesIDRetriever from '../../utils/SalesIDRetriever';
import request from '../../utils/request';

import './index.scss';

export default function TotalDueNow(props) {
    const [ due, setDue ] = React.useState([]);
    let schedule_mode = 'full';
    let returnValue = [];
    const getTotalDueNow = () => {
        request({
            url: getSystemSettings().api + '/inntopia/cart/depositSchedule',
            method: 'POST',
            data: {
                sessionId: getSessionId(),
                salesId: SalesIDRetriever()
            }
        })
        .then( (result) => {
            if (schedule_mode === 'due_now'){
                result.data.forEach((item) =>{
                    if (item.DueNow === 1){
                        returnValue = (
                            <React.Fragment>
                                <div className="label">
                                    Total due now:
                                </div>
                                <div className="dueprice">
                                    <Currency currency='usd' float={true} value={item.AmountDue}/>
                                </div>
                            </React.Fragment>
                        )
                    }
                })
            } else {
                result.data.forEach((item, idx) =>{
                    if (item.DueNow === 1){
                        returnValue.push(
                            <React.Fragment key={idx}>
                                <div className="label">
                                    Total due now:
                                </div>
                                <div className="dueprice">
                                    <Currency float={true} currency='usd' value={item.AmountDue}/>
                                </div>
                            </React.Fragment>
                        )
                    } else {
                        returnValue.push(
                            <React.Fragment key={idx}>
                                <div className="label">
                                   Due on {item.DateDue}:
                                </div>
                                <div className="dueprice">
                                    <Currency float={true} currency='usd' value={item.AmountDue}/>
                                </div>
                            </React.Fragment>
                        )
                    }
                })
            }
            setDue(returnValue)
        })

    }

    if (due.length === 0){
        getTotalDueNow()
    }

    return (
        <div className="totalDueNow">
            {due}
        </div>

    )
}
