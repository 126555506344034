import React, { useState } from 'react';
import moment from 'moment';

import './Header.scss';
import { getCookie, deleteCookie, setCookie } from '../../../../utils/CookiesRetriever';

import history from '../../../../history';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

import SEO from '../../../../components/Seo';
import request from '../../../../utils/request';
import SalesIDRetriever from '../../../../utils/SalesIDRetriever';
import { setSelectedPromocode, getSystemSettings } from '../../../../utils/SettingsRetriever';
import B2BTokenRegenerator from '../../../../utils/B2BTokenRegenerator';

import IntlMessage from '../../../../LocaleEngine';

export default () => {
    const [ selectedTab, setSelectedTab ] = useState(0);
    const [ name, setName ] = useState('Travel Agent');
    const [ company, setCompany ] = useState('');

    const homePath = '/b2b';
    const cookieName = 'b2b';

    const handleRetrieveCurrentUrl = () => {
        const _url = window.location.href.replace('http://', '').replace('https://', '')
        const url = _url.substring(_url.indexOf('/'));
        return url;
    }

    const handleRedirectToLoginPage = () => {
        const url = handleRetrieveCurrentUrl();
        if (url !== homePath && url !== homePath + '/admin') {
            // window.location.href = homePath;
            history.goBack();
        }
    }

    const getTravelAgent = () => {
        let userCookie = getCookie(cookieName);
        userCookie = JSON.parse(userCookie);
        let adminCookie = getCookie('b2btato') || '';
        adminCookie = adminCookie.split('~');
        let detailsCookie = getCookie('b2bagent');
        detailsCookie = JSON.parse(detailsCookie);
        if(userCookie === null && adminCookie.length <= 1){
            handleRedirectToLoginPage();
        }else{
            if (adminCookie.length > 1) {
                setName(adminCookie[0]);
                setCompany(adminCookie[1]);
            } else {
                if (detailsCookie) {
                    setName(detailsCookie.name);
                    setCompany(detailsCookie.agency);
                } else {
                    request({
                        url: getSystemSettings().api + '/inntopia/travel-agent',
                        method: 'POST',
                        data: {
                            travelAgentId: userCookie.id,
                            salesId: SalesIDRetriever()
                        }
                    })
                    .then( (data) => {
                        if (data.status === 200){
                            const date = moment();
                            date.add(1, 'days');
                            if (data.data.firstName && data.data.lastName) {
                                setName(data.data.firstName + ' ' + data.data.lastName);
                                setCookie('b2bagent', JSON.stringify({name: data.data.firstName + ' ' + data.data.lastName, agency: data.data.agencyName, user: data.data.userId}), date.toDate());
                            } else {
                                request({
                                    url: getSystemSettings().api + '/b2b/travelagentchannel/info?salesId=' + SalesIDRetriever() + '&username=' + data.data.userId,
                                    method: 'GET'
                                }).then(spotlioResponse => {
                                    if (spotlioResponse.data.name) {
                                        setName(spotlioResponse.data.name);
                                        setCookie('b2bagent', JSON.stringify({name: spotlioResponse.data.name, agency: data.data.agencyName, user: data.data.userId}), date.toDate());
                                    } else {
                                        setCookie('b2bagent', JSON.stringify({name: 'Travel Agent', agency: data.data.agencyName, user: data.data.userId}), date.toDate());
                                    }
                                })
                            }
                            setCompany(data.data.agencyName);
                        } else {
                            handleRedirectToLoginPage();
                        }
                    });
                }
            }
        }
    }

    const refreshName = (path) => {
        if (path.substring(0,5) === homePath + "/") {
            if (name === '' || company === '') {
                getTravelAgent();
            }
        }
    }

    history.listen((location, action) => {
        getTravelAgent();
        refreshName(location.pathname);
        B2BTokenRegenerator();
    })

    if (name === '' || company === '') {
        refreshName(handleRetrieveCurrentUrl());
    }

    const handleLogout = () => {
        deleteCookie(cookieName);
        deleteCookie('b2btato');
        deleteCookie('b2bagent');
        setSelectedPromocode('');
        if (window.location.href.match('admin'))
            window.location.href = homePath + '/admin';
        else
            window.location.href = homePath;
    }

    const handleToggleViewMode = (id) => {
        setSelectedTab(id);
        if (id === 1) window.location.href = "/";
        else history.push('/b2b/dashboard');
    }

    const isTATOAdmin = () => {
        const cookie = getCookie(cookieName);
        let isAdmin = false;

        if (cookie) {
            if (cookie.split('~')[3] === "TATO") {
                isAdmin = true;
            }
        }

        return isAdmin;
    }

    const handleUserMenu = (event) => {
        switch(event.target.value) {
            case "LogOut":
                handleLogout();
                break;
            default:
                break;
        }
    }

    const handleAvatar = () => {
        let ret = "";
        name.split(" ").forEach(idx => {
            ret = ret + idx.substring(0, 1); // gets first letter of each name word
        });
        return <Avatar className="avatar">{ret}</Avatar>
    }

    return (
        <div className="Header">
            <SEO title="B2B Home" />
            <div className="InnerWrapper">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <img className="Banner" onClick={() => handleToggleViewMode(0)} src={getSystemSettings().assets.logo} alt={getSystemSettings().title} />
                            </td>
                            <td>
                                <div className="SwitchesWrapper">
                                        {
                                            window.location.href.match('admin')
                                            ? ''
                                            : (

                                                <div className="TopBarSwitches">
                                                    <Button onClick={() => handleToggleViewMode(0)} className={selectedTab === 0 ? "selected" : ""}>
                                                        <IntlMessage id="b2b.header.buttons.dashboard" />
                                                    </Button>
                                                    <Button onClick={() => handleToggleViewMode(1)} className={selectedTab === 1 ? "selected" : ""}>
                                                        <IntlMessage id="b2b.header.buttons.booking" />
                                                    </Button>
                                                </div>
                                            )
                                        }
                                </div>
                            </td>
                            <td>
                                <div className="UserInformation">
                                    <Select onChange={handleUserMenu} value={"UserName"} className="Picker" name="UserActions" MenuProps={{
                                            getContentAnchorEl: null,
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            }
                                        }}>
                                        <MenuItem value="UserName" className="UserNameDetail">
                                            <div className="UserDetails">
                                                <strong>{name}</strong><br/>
                                                <small>{company}</small>
                                            </div>
                                            <div className="UserAvatar">
                                                { handleAvatar() }
                                            </div>
                                        </MenuItem>
                                        { isTATOAdmin() ? <MenuItem value="TATOAdmin">Adminland</MenuItem> : '' }
                                        <MenuItem value="LogOut">
                                            <IntlMessage id="b2b.header.user.logout" />
                                        </MenuItem>
                                    </Select>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
