import React from 'react';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import iconSet from './selection.json';

const Icon = (props) => {
  const { size, icon, className } = props;
  return <IcomoonReact className={className} color={props.color ? props.color : 'inherit'} iconSet={iconSet} size={size} icon={icon} />;
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Icon.defaultProps = {
  size: '32px',
  className: 'icon'
};

export default Icon;
