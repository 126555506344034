/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { getSystemSettings } from '../../../utils/SettingsRetriever';

import './index.scss';

export default ({ childProps }) => {
    const configuration = getSystemSettings();

    function createMarkup(text) {
        return { __html: text };
    }

    return (
    <footer className="footerWrapper">
        <Container className="container" maxWidth="lg">
            {
                configuration.footer.map((item, index) => {
                    return (
                        <div key={index} className={"column " + item.class}>
                            {
                                item.title !== ''
                                ?
                                <Typography variant="h6" className="title" gutterBottom>
                                    {item.title}
                                </Typography> 
                                : null
                            }
                            <div dangerouslySetInnerHTML={createMarkup(item.content)}></div>
                        </div>
                    );
                })
            }
            {/*<div className="column c4 left">
                <div className="logo text center">
                    <a target="_blank" href="https://www.spotlio.com/">
                        <img alt="Spotlio Demo Site" src="https://shopspotlio.spotlio.com/bundles/spotliowebappfront/spotlio/images/footer/logo.png" />
                    </a>
                </div>
                <div className="text center">
                    <span>Central Reservations:</span> <strong><a href="tel:xxxxxxxxxx">(XXX) XXX-XXXX</a></strong>
                </div>
            </div>
            <div className="column c2 center">
                <div className="text">
                    <Typography variant="h6" className="title" gutterBottom>
                        Welcome to Spotlio Booking DEMO
                    </Typography>
                    <p>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                    </p>
                </div>
                <div className="links primary vertical">
                    <ul>
                        <li>
                            <a href="https://www.spotlio.com/" title="About Us">About Spotlio</a>
                        </li>
                        <li>
                            <a href="https://www.spotlio.com/contact-us/" title="Contact Us">Contact Us</a>
                        </li>
                    </ul>
                </div>
                <div className="text">
                    <p>
                        Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.
                    </p>
                </div>
                <div className="links secondary">
                    <ul>
                        <li>
                            <a href="https://www.spotlio.com/" title="About Us">About Spotlio</a>
                        </li>
                        <li>
                            <a href="https://www.spotlio.com/contact-us/" title="Contact Us">Contact Us</a>
                        </li>
                    </ul>
                </div>
                <div className="text copyright">© 2019 Spotlio. All Rights Reserved.</div>
            </div>*/}
        </Container>
    </footer>);
};
