import React, { useState } from 'react'
import { IntlProvider } from 'react-intl';

import BookingRouter from '../Routes';
import LoadingScreen from '../components/Layout/LoadingScreen';

import B2BRouter from '../B2B/Router';

import CustomerIdentifier from '../utils/CustomerIdentifier';
import LocaleRetriever from '../utils/LocaleRetriever';
import request from '../utils/request';

import '../components/Layout/LoadingScreen/LoadingScreen.scss';
import { getSystemSettings, setSessionId, getSessionId, setUserKey, getCustomerConfig, setCurrencyDatabase } from '../utils/SettingsRetriever';

export default () => {
    const [ loading, setLoading ] = useState(true);
    const [ customerState, setCustomerState ] = useState(false);
    const [ customerCalled, setCustomerCalled ] = useState(false);
    const [ locale, setLocale ] = useState(null);
    const [ error, setError ] = useState(null);
    let url = window.location.href.replace('http://', '').replace('https://', '');
    url = url.substring(url.indexOf('/'));

    if (!customerState && !customerCalled) {
        setCustomerCalled(true);
        CheckForLatestVersion().then(() => CustomerIdentifier()).then(r => saveConfig(r) ).then(r => handleLoading(r) ).catch(err => setError(err));
    }

    function CheckForLatestVersion() {
        return new Promise((resolve, reject) => {
            fetch('/meta.json?t=' + new Date().getTime()).then(version => version.json()).then(metaVersion => {
                if (global.appVersion !== metaVersion.version) {
                    window.location.reload(true);
                } else {
                    resolve();
                }
            })
        })
    }

    function generateSessionId() {
        request({
            url: getSystemSettings().api + '/inntopia/session?salesid=' + getSystemSettings().app.salesid,
            method: 'GET'
        })
        .then( (result) => {
            setSessionId(result.data.SessionId);
        });
        // eslint-disable-next-line
        String.prototype.hashCode = function() {
            var hash = 0, i, chr;
            if (this.length === 0) return hash;
            for (i = 0; i < this.length; i++) {
                chr   = this.charCodeAt(i);
                hash  = ((hash << 5) - hash) + chr;
                hash |= 0; // Convert to 32bit integer
            }
            hash = '' + hash;
            if (hash.substr(0,1) === '-') {
                hash = hash.substr(1);
            }
            return parseInt(hash);
        };
        // eslint-disable-next-line
        setUserKey(new String(window.document.location.host + '+' + (new Date().getTime()) + '*').hashCode());
    }

    function generateCurrencyDatabase() {
        const CurrencySettings = getSystemSettings().app.currency;
        request({
            url: 'https://api.exchangeratesapi.io/latest?base=' + CurrencySettings.list[CurrencySettings.default].base,
            method: 'GET'
        }).then(response => {
            setCurrencyDatabase(response.data);
        });
    }

    function saveConfig(config) {
        return new Promise((resolve, reject) => {
            import('../LocaleEngine/addLocales').then(locales => {
                setLocale(locales.default);
                resolve(config);
            });
        })
    }

    function handleLoading (c) {
        setCustomerState(c);
        setLoading(false);
    }

    const system = getSystemSettings();
    const config = getCustomerConfig();

    if (loading || error || !system.hasOwnProperty('categories')) {
        if (error) {
            return (
                <div className="LoadingScreen">
                    <h1 style={{transform: 'rotate(90deg)'}}>:-(</h1>
                    <p>Our systems returned the following error:</p>
                    <pre>{error}</pre>
                    <p>Please contact <a href="mailto:support@spotlio.com">support@spotlio.com</a> for help.</p>
                </div>
            )
        }
        return <LoadingScreen />
    } else {
        if (!getSessionId()){
            generateSessionId();
        }
        generateCurrencyDatabase();
        const retrievedLocale = LocaleRetriever();
        const appLocale = locale[retrievedLocale].locale;
        const appMessages = locale[retrievedLocale].messages;
        return (
            <IntlProvider locale={appLocale} messages={appMessages}>
                {
                    url.split('/')[1].substr(0,3) === 'b2b'
                    ? <B2BRouter />
                    : <BookingRouter system={system} config={config} customerState={customerState}/>
                }
            </IntlProvider>
        );
    }
}
