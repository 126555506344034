// retrieves item from local storage
const getFromLocalStorage = (name) => {
    const item = localStorage.getItem(name);
    if (item) {
        try {
            return JSON.parse(item)
        } catch (e) {
            if (process.env.NODE_ENV === 'development') console.warn('[SettingsRetriever] Could not get item ' + name + ': ', e)
            return item;
        }
    } else {
        if (process.env.NODE_ENV === 'development') console.warn('[SettingsRetriever] Could not get item ' + name)
        return false;
    }
}

// saves item in local storage
const setToLocalStorage = (name, settings) => {
    try {
        localStorage.setItem(name, JSON.stringify(settings));
        return true;
    } catch (e) {
        if (process.env.NODE_ENV === 'development') console.warn('[SettingsRetriever] Could not persist system settings: ', e);
        return false;
    }
}

// gets system settings as an object
const getSystemSettings = () => {
    return getFromLocalStorage('system');
}

// stores system settings as a string in local storage
const setSystemSettings = (settings) => {
    return setToLocalStorage('system', settings);
}

// gets the customer config
const getCustomerConfig = () => {
    return getFromLocalStorage('config');
}

// stores customer config as a string in local storage
const setCustomerConfig = (settings) => {
    return setToLocalStorage('config', settings);
}

// gets the customer session id
const getSessionId = () => {
    return getFromLocalStorage('sessionId');
}

// stores customer session id as a string in local storage
const setSessionId = (settings) => {
    return setToLocalStorage('sessionId', settings);
}

// gets the itinerary id
const getUserKey = () => {
    return getFromLocalStorage('userKey');
}

// stores customer spotlio id as a string in local storage
const setUserKey = (settings) => {
    return setToLocalStorage('userKey', settings);
}

// gets the itinerary id
const getItineraryId = () => {
    return getFromLocalStorage('itineraryId');
}

// stores itinerary id as a string in local storage
const setItineraryId = (settings) => {
    return setToLocalStorage('itineraryId', settings);
}

// gets the sort order
const getSortOrder = () => {
    return getFromLocalStorage('sortOrder');
}

// stores sort order as a string in local storage
const setSortOrder = (settings) => {
    return setToLocalStorage('sortOrder', settings);
}

// gets the form data
const getFormData = () => {
    return getFromLocalStorage('formData');
}

// stores form data as a string in local storage
const setFormData = (settings) => {
    return setToLocalStorage('formData', settings);
}

// gets the form data
const getFormDataLift = () => {
    return getFromLocalStorage('formDataLift');
}

// stores form data as a string in local storage
const setFormDataLift = (settings) => {
    return setToLocalStorage('formDataLift', settings);
}

// gets the form data
const getSelectedPromocode = () => {
    return getFromLocalStorage('selectedPromocode');
}

// stores form data as a string in local storage
const setSelectedPromocode = (settings) => {
    return setToLocalStorage('selectedPromocode', settings);
}

// gets the currency database
const getCurrencyDatabase = () => {
    return getFromLocalStorage('CurrencyDatabase');
}

// stores currency database as a string in local storage
const setCurrencyDatabase = (settings) => {
    return setToLocalStorage('CurrencyDatabase', settings);
}

// gets user settings as an object
const getUserSettings = () => {
    return getFromLocalStorage('user');
}

// stores user settings as a string in local storage
const setUserSettings = (settings) => {
    return setToLocalStorage('user', settings);
}

// export methods
export {
    getSystemSettings,
    setSystemSettings,
    getCustomerConfig,
    setCustomerConfig,
    getSessionId,
    setSessionId,
    getUserKey,
    setUserKey,
    getItineraryId,
    setItineraryId,
    getSortOrder,
    setSortOrder,
    getFormData,
    setFormData,
    getFormDataLift,
    setFormDataLift,
    getSelectedPromocode,
    setSelectedPromocode,
    getCurrencyDatabase,
    setCurrencyDatabase,
    getUserSettings,
    setUserSettings
};
