import React from 'react';

import CartCell from '../../Cell/Cart';

export default function Agrupation(props){
    const { agrupation, idx } = props;

    function removeItemModalOpen(item, value){
        props.deleteItem(item, value)
    }

    return (
        <div className="agrupation" key={idx}>
            <div className="header">
                {agrupation.name}
            </div>
            {
                agrupation.items.map((item, index) => {
                    return (<CartCell key={index} deleteItem={removeItemModalOpen.bind(this)} index={index} agrupation={agrupation} item={item} />)                    
                })
            }
        </div>
    )
}
