import styled from 'styled-components';

import _variables from '../themeVariables';

export default (system) => {
    const variables = _variables(system);
    
    return styled.div`
        button{
            &.Primary{
                background-color: ${variables.b2b_section_buttons_primary_background};
                color: ${variables.b2b_section_buttons_primary_color};
                &:hover {
                    background-color: ${variables.b2b_section_buttons_primary_hover_background};
                    color: ${variables.b2b_section_buttons_primary_hover_color};
                }
            }
            &.Secondary {
                background-color: ${variables.b2b_section_buttons_secondary_background};
                color: ${variables.b2b_section_buttons_secondary_color};
                &:hover {
                    background-color: ${variables.b2b_section_buttons_secondary_hover_background};
                color: ${variables.b2b_section_buttons_secondary_hover_color};
                }
            }
        }
        .login-container{
            background-color: ${variables.b2b_login_background};
            form{
                background-color: ${variables.b2b_login_background};
                .ctaLogin {
                    border-color: ${variables.b2b_login_cta_border};
                    background-color: ${variables.b2b_login_cta_background};
                    color: ${variables.b2b_login_cta_color};
                }
            }
        }
        .Header{
            background-color: ${variables.b2b_topbar_background};
            .InnerWrapper{
                .SwitchesWrapper{
                    .TopBarSwitches{
                        background-color: ${variables.b2b_topbar_switches_background};
                        button{
                            color: ${variables.b2b_topbar_switches_nonselected_color};
                            background-color: ${variables.b2b_topbar_switches_nonselected_background};
                            &.selected{
                                background-color: ${variables.b2b_topbar_switches_selected_background};
                                color: ${variables.b2b_topbar_switches_selected_color};
                            }
                        }
                    }
                }
                .UserInformation{
                    .UserAvatar{
                        .avatar{
                            background-color: ${variables.b2b_topbar_buttons_background};
                            color: ${variables.b2b_topbar_buttons_color};
                            svg{
                                fill: ${variables.b2b_topbar_buttons_color};
                            }
                        }
                    }
                }
            }
        }
        .Dashboard .OptionsWrapper .Option{
            a{
                border-color: ${variables.b2b_dashboard_option_border};
                background-color: ${variables.b2b_dashboard_option_background};
                svg{
                    fill: ${variables.b2b_dashboard_option_icon_color};
                }
                &:hover{
                    border-color: ${variables.b2b_dashboard_option_hover_border};
                    background-color: ${variables.b2b_dashboard_option_hover_background};
                    svg{
                        fill: ${variables.b2b_dashboard_option_hover_icon_color};
                    }
                }
            }
            span{
                color: ${variables.b2b_dashboard_option_color};
            }
        }
        .DashboardSidebar {
            background-color: ${variables.b2b_dashboard_sidebar_background};
            .ContentWrapper{
                .Option{
                    border-color: ${variables.b2b_dashboard_option_border};
                    background-color: ${variables.b2b_dashboard_option_background};
                    svg{
                        fill: ${variables.b2b_dashboard_option_icon_color};
                    }
                    &:hover{
                        border-color: ${variables.b2b_dashboard_option_hover_border};
                        background-color: ${variables.b2b_dashboard_option_hover_background};
                        svg{
                            fill: ${variables.b2b_dashboard_option_hover_icon_color};
                        }
                    }
                    &.active{
                        border-color: ${variables.b2b_dashboard_option_active_border};
                        background-color: ${variables.b2b_dashboard_option_active_background};
                        svg{
                            fill: ${variables.b2b_dashboard_option_active_icon_color};
                        }
                    }
                }
            }
        }
        .PromocodeList .PromocodesContainer .SearchRegion, .Guests, .Itineraries {
            a {
                color: ${variables.b2b_section_buttons_link_color}
                &:hover{
                    color: ${variables.b2b_section_buttons_link_hover_color}
                }
            }
            .Search {
                button{
                    &.AddButton {
                        background-color: ${variables.b2b_section_buttons_add_background};
                        svg{
                            fill: ${variables.b2b_section_buttons_add_color};
                        }
                    }
                    &.SearchButton {
                        background-color: ${variables.b2b_section_buttons_search_background};
                        color: ${variables.b2b_section_buttons_search_color};
                    }
                    &.ClearButton {
                        background-color: ${variables.b2b_section_buttons_clear_background};
                        color: ${variables.b2b_section_buttons_clear_color};
                    }
                }
                .CriteriaSelector {
                    border-color: ${variables.b2b_section_selector_border};
                    color: ${variables.b2b_section_selector_color};
                    background-color: ${variables.b2b_section_selector_background};
                    svg{
                        color: ${variables.b2b_section_selector_color};
                    }
                }
                .CriteriaSwitches {
                    background-color: ${variables.b2b_section_switcher_background};
                    button{
                        background-color: ${variables.b2b_section_switcher_button_background};
                        color: ${variables.b2b_section_switcher_button_color};
                        &.selected {
                            background-color: ${variables.b2b_section_switcher_button_selected_background};
                            color: ${variables.b2b_section_switcher_button_selected_color};
                        }
                    }
                }
            }
        }
    `;
}