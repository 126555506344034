import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Router, Route, Switch, Redirect } from "react-router-dom";

import history from './history';
import LoadingScreen from './components/Layout/LoadingScreen';
import PathTranslator from './utils/PathTranslator';
import BookingLayout from './components/Layout';
import CheckProtected from './utils/Auth';

// customer pages
const AsyncHome = lazy(() => import('./containers/Home'));
const AsyncActivities = lazy(() => import('./containers/Activities'));
const AsyncMerchandise = lazy(() => import('./containers/Merchandise'));
const AsyncPackages = lazy(() => import('./containers/Packages'));
const AsyncPackage = lazy(() => import('./containers/Package'));
const AsyncCart = lazy(() => import('./containers/Cart'));
const AsyncRegister = lazy(() => import('./containers/Register'));
const AsyncPayment = lazy(() => import('./containers/Payment'));
const AsyncReview = lazy(() => import('./containers/Review'));
const AsyncResetPassword = lazy(() => import('./containers/ResetPassword'));
const AsyncLogout = lazy(() => import('./containers/Logout'));
const AsyncSuppliers = lazy(() => import('./containers/Suppliers'));
const AsyncSupplier = lazy(() => import('./containers/Supplier'));
const AsyncLiftTickets = lazy(() => import('./containers/LiftTickets'));
const AsyncTicketsView = lazy(() => import('./containers/LiftTickets/TicketsView'));
const AsyncCheckoutComplete = lazy(() => import('./containers/Checkout/complete'));
const AsyncCheckoutError = lazy(() => import('./containers/Checkout/error'));
const AsyncProfile = lazy(() => import('./containers/Profile'));




const AsyncNotFound = lazy(() => import('./containers/NotFound'));

const extractConfig = (path, system, wholeConfig = false) => {
  let routes = [];
  if (system.hasOwnProperty('categories')) {
    system.categories.forEach(category => {
      if (category.code === path) {
        if (wholeConfig) routes = { id: system.id, ...category };
        else routes = category.href;
      }
    });
  }
  return routes;
}

const PrivateRoute = ({ render: Component, ...rest }) => {
  return <Route
    {...rest}
    render={(props) => (
      CheckProtected.getAuth() === true
      ? <Component {...props} />
      : window.location.href = '/b2b'
    )}
  />
};

export default (props) => {
  let activitiesRoutes = [];

  props.system.categories.forEach((val, idx) => {
    if (val.type === 'activities') {
      activitiesRoutes.push(
        <PrivateRoute key={idx} exact path={PathTranslator([extractConfig(val.code, props.system)])} render={(routeProps) => <AsyncActivities {...routeProps} section={val.code} configuration={extractConfig(val.code, props.system, true)} />} />,
      );
    }
    if (val.type === 'merchandise') {
      activitiesRoutes.push(
        <PrivateRoute key={idx} exact path={PathTranslator([extractConfig(val.code, props.system)])} render={(routeProps) => <AsyncMerchandise {...routeProps} section={val.code} configuration={extractConfig(val.code, props.system, true)} />} />,
      );
    }
    if (val.type === 'lift-tickets') {
      activitiesRoutes.push(
        <PrivateRoute key={idx} exact path={PathTranslator([extractConfig(val.code, props.system)])} render={(routeProps) => <AsyncLiftTickets {...routeProps} section={val.code} configuration={extractConfig(val.code, props.system, true)} />} />,
        )
      activitiesRoutes.push(
        <PrivateRoute key={idx.toString() + 'ticketsview'} exact path={PathTranslator(['/products/lift-tickets/results'])} render={(routeProps) => <AsyncTicketsView {...routeProps} section={val.code} configuration={extractConfig(val.code, props.system, true)} />} />,
      )
    }
  });

  const defaultBookingRoutes = [
    <PrivateRoute key="cart" exact path={PathTranslator(["/cart"])} render={(routeProps) => <AsyncCart {...routeProps} section="cart" />} />,
    <PrivateRoute key="register" exact path={PathTranslator(["/register"])} render={(routeProps) => <AsyncRegister {...routeProps} section="register" />} />,
    <PrivateRoute key="payment" exact path={PathTranslator(["/payment"])} render={(routeProps) => <AsyncPayment {...routeProps} section="payment" />} />,
    <PrivateRoute key="review" exact path={PathTranslator(["/review"])} render={(routeProps) => <AsyncReview {...routeProps} section="review" />} />,
    <PrivateRoute key="resetpassword" exact path={PathTranslator(["/resetpassword"])} render={(routeProps) => <AsyncResetPassword {...routeProps} section="resetpassword" />} />,
    <PrivateRoute key="logout" exact path={PathTranslator(["/logout"])} render={(routeProps) => <AsyncLogout {...routeProps} section="logout" />} />,
    <PrivateRoute key="checkout-complete" exact path={PathTranslator(["/checkout/completed"])} render={(routeProps) => <AsyncCheckoutComplete {...routeProps} section="checkout" />} />,
    <PrivateRoute key="checkout-error" exact path={PathTranslator(["/checkout/error"])} render={(routeProps) => <AsyncCheckoutError {...routeProps} section="checkout" />} />,
    <PrivateRoute key="profile" exact path={PathTranslator(["/profile"])} render={(routeProps) => <AsyncProfile {...routeProps} section="profile" />} />

  ];

  const sectionRoutes = [
    <PrivateRoute key="products" exact path={PathTranslator(["/products/lodging", ...extractConfig('lodging', props.system)])} render={(routeProps) => <AsyncHome {...routeProps} section="lodging" configuration={extractConfig('lodging', props.system, true)} />} />,
    <PrivateRoute key="packages" exact path={PathTranslator(["/packages", ...extractConfig('packages', props.system)])} render={(routeProps) => <AsyncPackages {...routeProps} section="packages" configuration={extractConfig('packages', props.system, true)} />} />,
    <PrivateRoute key="package" exact path={PathTranslator(["/packages/:slug/:packageId", ...extractConfig('package', props.system)])} render={(routeProps) => <AsyncPackage {...routeProps} section="packages" configuration={extractConfig('packages', props.system, true)} />} />,
    <PrivateRoute key="suppliers" exact path={PathTranslator(["/suppliers"])} render={(routeProps) => <AsyncSuppliers {...routeProps} section="suppliers" />} />,
    <PrivateRoute key="supplier" exact path={PathTranslator(["/supplier/:slug/:supplierId"])} render={(routeProps) => <AsyncSupplier {...routeProps} section="supplier" />} />,
    ...activitiesRoutes,
    ...defaultBookingRoutes
  ];

  return (
    <BrowserRouter>
      <Router history={history}>
        <Suspense fallback={<LoadingScreen message="Loading..." fullScreen />}>
          <BookingLayout customer={props.customerState} system={props.system} config={props.config}>
            <Switch>
              <Route exact path="/" render={() => <Redirect to={props.system.app.defaultPage || "/products/lodging"} />} />
              { sectionRoutes.map(idx => idx) }
              <Route component={AsyncNotFound} />
            </Switch>
          </BookingLayout>
        </Suspense>
      </Router>
    </BrowserRouter>
  )
}
