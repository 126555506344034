import { getCookie } from "./CookiesRetriever";
import { getSystemSettings } from "./SettingsRetriever";

export default () => {
    const SystemSettings = getSystemSettings();
    const b2bCookie = getCookie('b2b');

    let salesId = 0;

    if (SystemSettings.app) salesId = SystemSettings.app.salesid;
    if (b2bCookie)          salesId = JSON.parse(b2bCookie).channel;

    return salesId
}
