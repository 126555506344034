import React from 'react';

import moment from 'moment';
import { getSystemSettings } from '../../utils/SettingsRetriever';

export default function DateFormat(props){
    const {date, format} = props;
    let defaultFormat = getSystemSettings().app.format_dates
    return (
        <React.Fragment>
            {moment(new Date(date)).format(format ? format : defaultFormat)}
        </React.Fragment>
    )
}