import moment from 'moment';

import request from './request';
import { getSessionId, getSystemSettings } from './SettingsRetriever';
import SalesIDRetriever from './SalesIDRetriever';
import { getCookie, setCookie, deleteCookie, } from './CookiesRetriever';

export default () => {
    const _b2bCookie = getCookie('b2b');
    if (_b2bCookie) {
        let b2bCookie = JSON.parse(_b2bCookie);
        const now = moment();
        const timeRemaining = moment(b2bCookie.token.expires).unix() - now.unix();
        if (timeRemaining <= 1800 && timeRemaining > 0) {
            request({
                url: getSystemSettings().api + '/inntopia/travel-agent/token',
                method: 'POST',
                data: {
                    sessionId: getSessionId(),
                    salesId: SalesIDRetriever()
                }
            }).then(response => {
                const tomorrow = moment().add(1, 'days');
                b2bCookie.token = response.data;
                setCookie('b2b', JSON.stringify(b2bCookie), tomorrow.toDate());
            });
        }
        if (timeRemaining <= 0) {
            deleteCookie('b2b');
            deleteCookie('b2btato');
            deleteCookie('b2bagent');
            window.location.href = '/b2b';
        }
    }
}
