import React from 'react';

import { ThemeProvider as MaterialUIProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import Header from '../../components/Layout/Header';
import ClientTheme from '../../../components/Layout/Themes/client';
import {getSystemSettings} from '../../../utils/SettingsRetriever';

import './Layout.scss';

export default class Layout extends React.Component {
    MaterialUITheme = createMuiTheme({
        typography: {
            fontFamily: [
                '"Nunito"',
                '"Open Sans"',
                '"Roboto"',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 700
        },
    });
    
    render() {
        return (
            <MaterialUIProvider theme={this.MaterialUITheme}>
                <CssBaseline />
                <ClientTheme system={getSystemSettings()} className="b2b-application">
                    <Header />
                    <Container maxWidth="lg" className="b2bWrapper" component="section">
                        {this.props.children}
                    </Container>
                </ClientTheme>
            </MaterialUIProvider>
        )
    }
}
