/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { Link } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import { TextField } from '@material-ui/core';

import SpotlioIcon from '../../Icon';
import Modal from '../../Modal';
import CurrencySelector from '../../CurrencySelector';

import { getCookie, deleteCookie } from '../../../utils/CookiesRetriever';
import request from '../../../utils/request';
import SalesIDRetriever from '../../../utils/SalesIDRetriever';
import history from '../../../history';

import { setSelectedPromocode, getSystemSettings } from '../../../utils/SettingsRetriever';
import B2BTokenRegenerator from '../../../utils/B2BTokenRegenerator';

import AddGuestModal from '../../../B2B/containers/Dashboard/Guests/Modal';

import './index.scss';

export default (props) => {
    //eslint-disable-next-line
    const [ b2bGuests, setB2BGuests ] = React.useState(null);
    const [ currentGuest, setCurrentGuest ] = React.useState(null);

    const [ searched, setSearched ] = React.useState(false);

    const [ travelAgentMenuOpen, setTravelAgentMenuOpen ] = React.useState(false);
    const [ anchorElement, setAnchorElement ] = React.useState(null);

    const [ guestsModal, setGuestsModal ] = React.useState(false);
    const [ guestsModalMode, setGuestsModalMode ] = React.useState("search");
    const [ guestsSearchCriteria, setGuestsSearchCriteria ] = React.useState("firstName");
    const [ guestsSearchPattern, setGuestsSearchPattern ] = React.useState("");
    const [ forceGuestModalSpan, setForceGuestModalSpawn ] = React.useState(false);

    history.listen((location, action) => {
        if (location.hash === "#attachGuestsModal" && !guestsModal) {
            setGuestsModal(true);
        }
        B2BTokenRegenerator();
    });

    const travelAgentToggleModal = (event) => {
        const tamo = !travelAgentMenuOpen;
        setAnchorElement(event.currentTarget);
        setTravelAgentMenuOpen(tamo);
    }

    const getCurrentGuest = () => {
        request({
            url: getSystemSettings().api + '/inntopia/travel-agent/guest/current',
            method: 'POST',
            data: {
                token: props.b2bCookie.token.token,
                salesId: SalesIDRetriever(),
            }
        }).then(response => {
            if (response.status === 200) {
                setCurrentGuest(response.data);
            }
        })
    }

    const attachGuestAction = (guestId) => {
        request({
            url: getSystemSettings().api + '/inntopia/travel-agent/guest/attach',
            method: 'POST',
            data: {
                token: props.b2bCookie.token.token,
                salesId: SalesIDRetriever(),
                guestId: guestId
            }
        }).then(response => {
            if (response.status === 200) {
                getCurrentGuest();
            }
        })
    }

    const attachGuest = (guestId) => {
        if (currentGuest) {
            dettachGuest(currentGuest.id, guestId);
        } else {
            attachGuestAction(guestId);
        }
    }

    const dettachGuest = (guestId, attachingGuest = null) => {
        request({
            url: getSystemSettings().api + '/inntopia/travel-agent/guest/remove',
            method: 'POST',
            data: {
                token: props.b2bCookie.token.token,
                salesId: SalesIDRetriever(),
                guestId: guestId
            }
        }).then(response => {
            if (response.status === 200) {
                setCurrentGuest(null);
                getCurrentGuest();
                if (attachingGuest) {
                    attachGuestAction(attachingGuest);
                }
            }
        })
    }

    const handleLogout = () => {
        deleteCookie('b2b');
        deleteCookie('b2bagent');
        deleteCookie('b2btato');
        setSelectedPromocode('');
        window.location.href = '/b2b';
    }

    const handleClearSearch = () => {
        setGuestsSearchPattern("");
        setGuestsSearchCriteria("firstName");
        setGuestsModalMode("show");
        handleSearch(true);
    }

    const handleSearch = (doNotSearch = false) => {
        let data = {
            token: props.b2bCookie.token.token,
            salesId: SalesIDRetriever()
        };
        if (guestsSearchPattern !== "" && !doNotSearch) {
            data[guestsSearchCriteria] = guestsSearchPattern;
        }
        request({
            url: getSystemSettings().api + '/inntopia/travel-agent/guest/search',
            method: 'POST',
            data: data
        }).then(guests => {
            if (guests.status === 200) {
                setB2BGuests(guests.data)
                setGuestsModalMode("show");
            } else {
                setB2BGuests([])
                setGuestsModalMode("show");
            }
        });
    }

    const b2bCookie = JSON.parse(getCookie('b2b') || '{}');
    if (b2bCookie.channel && !searched) {
        setSearched(true);
        getCurrentGuest();
        handleSearch();
    }

    const toggleGuestsModal = () =>{
        if (guestsModal === true){
            setGuestsModal(false)
            history.push('#')
        } else {
            setGuestsModal(true)
        }
    }

    const currentGuestButton = (
        <Avatar className="avatar" onClick={() => setGuestsModal(true)}>
            {
                currentGuest
                ? currentGuest.firstName.substring(0, 1) + currentGuest.lastName.substring(0, 1)
                : <SpotlioIcon icon="va-account_loggedout" />
            }
        </Avatar>
    );

    const goToDashBoard = () => {
        setSelectedPromocode('');
        window.location.href = '/b2b/dashboard'
    }

    const detailsCookie = JSON.parse(getCookie('b2bagent'));
    let agentName = detailsCookie.name.split(' ')[0].substring(0, 1) + detailsCookie.name.split(' ')[1].substring(0, 1);

    return (
        <React.Fragment>
            <div className="HeaderWrapper">
                <div className="SwitchesWrapper">
                    <div className="TopBarSwitches">
                        <Button onClick={()=>goToDashBoard()}>Dashboard</Button>
                        <Button className="selected">Booking</Button>
                    </div>
                </div>
                <div className="tatoButtons">
                    <div className="tatoButton">
                        <Avatar className="avatar" onClick={(e) => travelAgentToggleModal(e)}>
                            { agentName }
                        </Avatar>
                    </div>
                    <div className="tatoButton">
                        {
                            currentGuest
                            ? currentGuestButton
                            : (
                                <Badge className="NoGuest" badgeContent="!">
                                    { currentGuestButton }
                                </Badge>
                            )
                        }
                    </div>
                    <div className="tatoButton">
                        <Link to="/cart">
                            <Badge className="CartItems" badgeContent="0" color="primary">
                                <Avatar className="avatar">
                                    <SpotlioIcon icon="sp2-shopping-cart" />
                                </Avatar>
                            </Badge>
                        </Link>
                    </div>
                    <div className="tatoButton">
                        <CurrencySelector className="avatar" mode="b2b" />
                    </div>
                </div>
            </div>
            <Menu
                style={{marginTop: '3em'}}
                id="travelAgentMenu"
                anchorEl={anchorElement}
                keepMounted
                open={travelAgentMenuOpen}
                onClose={() => setTravelAgentMenuOpen(false)}
            >
                <MenuItem onClick={() => window.location.href ='/b2b/dashboard'}>
                    Go to Dashboard
                </MenuItem>
                <MenuItem onClick={() => handleLogout()}>
                    Log Out
                </MenuItem>
            </Menu>
            <Modal
                open={guestsModal}
                title="Attach Guest"
                className="attachGuest"
                closeFn={()=>toggleGuestsModal(false)}
                footerContent={
                    <React.Fragment>
                        <Button className="secondary" onClick={() => setGuestsModalMode(guestsModalMode === "show" ? "search" : "show")}>
                            { guestsModalMode === "show" ? "Search" : "Show All" }
                        </Button>
                        <AddGuestModal
                            buttonClass="secondary"
                            onClose={() => setForceGuestModalSpawn(false)}
                            forceModalSpawn={forceGuestModalSpan}
                            userId={null}
                            env="b2c"
                            onEditConfirm={() => handleSearch()}
                        />
                        <Button onClick={() => toggleGuestsModal(false)} className="secondary">Close</Button>
                    </React.Fragment>
                }
            >
                <React.Fragment>
                    <div className="currentGuest">
                        <div className="guestDetails">
                            <h2>Current Guest</h2>
                        {
                            currentGuest ? (
                                <React.Fragment>
                                    <h3>{currentGuest.id + " - " + currentGuest.firstName + " " + currentGuest.lastName + " (" + currentGuest.country + ")"}</h3>
                                    <Button onClick={() => dettachGuest(currentGuest.id)}>
                                        Detach Guest
                                    </Button>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <h3>No guest is attached at the moment.</h3>
                                </React.Fragment>
                            )
                        }
                        </div>
                    </div>
                    <div className="guestsList">
                        {
                            guestsModalMode === "show" ? (
                                b2bGuests && (
                                    b2bGuests.length > 0 ? (
                                        b2bGuests.map((val, idx) => {
                                            return (
                                                <div key={idx} className="guest">
                                                    <p className="guestDetails">{ val.id + " - " + val.firstName + " " + val.lastName }</p>
                                                    <Button className="attachButton" onClick={() => attachGuest(val.id)}>Attach</Button>
                                                </div>
                                            )
                                        })
                                    ) : (
                                        <React.Fragment>
                                            <h3>No guests found.</h3>
                                        </React.Fragment>
                                    )
                                )
                            ) : (
                                <div className="SearchRegion">
                                    <h3>Search by</h3>
                                    <Select className="Switch" value={guestsSearchCriteria} onChange={(e) => setGuestsSearchCriteria(e.target.value)}>
                                        <MenuItem value="firstName">First Name</MenuItem>
                                        <MenuItem value="lastName">Last Name</MenuItem>
                                        <MenuItem value="phone">Phone</MenuItem>
                                        <MenuItem value="email">Email</MenuItem>
                                    </Select>
                                    <TextField className="SearchPattern" value={guestsSearchPattern} onChange={(e) => setGuestsSearchPattern(e.target.value)} />
                                    <div className="ButtonsRegion">
                                        <Button onClick={() => handleSearch()} className="attachButton primary">Search</Button>
                                        <Button onClick={() => handleClearSearch()} className="attachButton secondary">Clear</Button>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </React.Fragment>
            </Modal>
        </React.Fragment>
    )
}
