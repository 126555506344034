import axios from 'axios';

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {object} [options] The options we want to pass to "axios"
 *
 * @return {object}           The response data
 *
 * more info about request: https://developer.mozilla.org/en-US/docs/Web/API/Request
 */
export default function request(options) {
  // cache killer
  let separator = "?";
  if (options.url.match(/\?/)) separator = "&";
  options.url = options.url + separator + "t=" + new Date().getTime()
  // request itself
  return axios(options)
    .then((response) => {
      if(options.checkStatus){
        return checkStatus(response);
      }
      return response;
    });
}
