import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import './LoadingScreen.scss';

export default (props) => (
    <div className={"LoadingScreen " + (props.fullScreen ? " Fullscreen" : "")} style={props.css}>
        <CircularProgress />
    </div>
)
