import ca from './locales/ca.json';
import de from './locales/de.json';
import es from './locales/es.json';
import it from './locales/it.json';
import fr from './locales/fr.json';
import zh from './locales/zh.json';

export default (key) => {
    let pathKeys = key;
    if(!Array.isArray(key)){
        pathKeys = [key];
    }

    pathKeys.forEach(key => {
        if (ca[key]) pathKeys.push("/ca" + ca[key]);
        if (de[key]) pathKeys.push("/de" + de[key]);
        if (es[key]) pathKeys.push("/es" + es[key]);
        if (fr[key]) pathKeys.push("/fr" + fr[key]);
        if (it[key]) pathKeys.push("/it" + it[key]);
        if (zh[key]) pathKeys.push("/zh" + zh[key]);
    });

    return pathKeys;
}
