import React from 'react';
// import styled from 'styled-components';

// import _variables from './themeVariables';
import MA from './theme/MainApplication';
import BA from './theme/B2BApplication';

export default ({ className, children, system }) => {

    if(className === 'main-application'){
        const MainApplication = MA(system);

        return(
            <MainApplication className={className}>
                {children}
            </MainApplication>
        );
    }

    if(className === 'b2b-application'){
        const B2BApplication = BA(system);

        return(
            <B2BApplication className={className}>
                {children}
            </B2BApplication>
        );
    }

    return '';
}
