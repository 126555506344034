import React, { Component } from 'react';

import { getSessionId, getCustomerConfig } from '../../utils/SettingsRetriever';
import request from '../../utils/request';

import Button from '@material-ui/core/Button';

import * as Icon from 'react-feather';
import { ShoppingCart } from 'react-feather';

import LoadingScreen from '../../components/Layout/LoadingScreen';
import Currency from '../../components/Currency';
import SEO from '../../components/Seo';
import Modal from '../../components/Modal';
import TabGenerator from '../../components/Layout/HeaderTabs/TabGenerator';
import TotalDueNow from '../../components/TotalDueNow';
import Products from '../../components/List/Cart/products';

import { getSystemSettings, setSessionId } from '../../utils/SettingsRetriever';
import { getCookie } from '../../utils/CookiesRetriever';
import SalesIDRetriever from '../../utils/SalesIDRetriever';
import history from '../../history';

import './index.scss';

export default class Cart extends Component{
    constructor(props){
        super(props);
        this.state = {
            cart:[],
            loading:true,
            total: 0,
            taxes: 0,
            taxesList: [],
            deletedItem:{},
            itineraryId: 0,
            categories: [],
            taxesModal: false,
            removeItemModal: false,
            itinerarySaved: false,
            attachGuestModal: false,
            b2bCookie: JSON.parse(getCookie('b2b'))
        }
    }

    getItinerary = () => {
        request({
            url: getSystemSettings().api + '/cart',
            method: 'POST',
            data: {
                id: getSystemSettings().id,
                sessionId: getSessionId(),
                salesId: getCustomerConfig()['config']['salesId']
            }
        })
        .then( (data) => {
            if (data.data.items){
                const cart = data.data.items;
                const taxes = data.data.fees;
                let taxesfees = 0
                let total = 0
                cart.forEach((agrupation, idx) => {
                    agrupation.items.forEach((item) => {
                        total += parseFloat(item.Price);
                    })
                })
                taxes.forEach((tax) => {
                    tax.Amount ?
                    taxesfees += tax.Amount :
                    taxesfees += tax.price
                })
                let stateUpdate = {total: total, taxes:taxesfees, loading: false, cart: cart, taxesList: taxes.sort(this.compareTax)};
                if (cart.length > 0) {
                    stateUpdate['itineraryId'] = data.data.itineraryid;
                }
                this.setState(stateUpdate);
            } else {
                this.setState({loading: false})
            }
        })
    }

    compareTax = (a, b) => {
        if (!a.Amount){
            if (a.price < b.Amount) {
                return -1;
            }
            if (a.price > b.Amount) {
                return 1;
            }
        } else if(!b.Amount) {
            if (a.Amount < b.price) {
                return -1;
            }
            if (a.Amount > b.price) {
                return 1;
            }
        }
        else {
            if (a.Amount < b.Amount) {
                return -1;
            }
            if (a.Amount > b.Amount) {
                return 1;
            }
        }

        return 0;
    }

    gotoRegister = () => {
        if (JSON.parse(getCookie('b2b'))){
            request({
                url: getSystemSettings().api + '/inntopia/travel-agent/guest/current',
                method: 'POST',
                data: {
                    token: JSON.parse(getCookie('b2b')).token.token,
                    salesId: SalesIDRetriever(),
                }
            }).then(response => {
                if (response.status === 200){
                    history.push('/register')
                } else {
                    history.push('#attachGuestsModal')
                }
            })
        } else {
            history.push('/register')
        }
    }
    componentWillReceiveProps(nextProps){
        if (nextProps.loading ===  true && this.state.loading === false){
            this.setState({loading: nextProps.loading})
        }
        if (nextProps.loading === 'finished' && this.state.loading !== 'finished'){
            this.getItinerary();
        }
    }

    componentWillMount(){
        let config = getSystemSettings()
        this.setState({categories: config.categories})
        this.getItinerary();
    }

    removeItemModalOpen = (item, value) => {
        this.setState({deletedItem: item}, () => {
            this.setState({removeItemModal: value})
        })
    }

    taxesModalOpen = (value) => {
        this.setState({...this.state, taxesModal: value});
    }

    handleDeleteItem = () => {
        this.setState({loading: true, removeItemModal: false})
        request({
            url: getSystemSettings().api + '/inntopia/cart/remove',
            method: 'POST',
            data: {
                sessionId: getSessionId(),
                itemId: this.state.deletedItem.ItineraryItemId,
                salesId: getCustomerConfig()['config']['salesId']
            }
        })
        .then( (data) => {
            this.getItinerary();
        });
    }

    saveItinerary = () => {
        if(this.state.b2bCookie){
            request({
                url: getSystemSettings().api + '/inntopia/travel-agent/guest/current',
                method: 'POST',
                data: {
                    token: this.state.b2bCookie.token.token,
                    salesId: SalesIDRetriever()
                }
            })
            .then( (result) => {
                if(result.status === 200){
                    request({
                        url: getSystemSettings().api + '/inntopia/cart/save',
                        method: 'POST',
                        data: {
                            sessionId: getSessionId(),
                            salesId: SalesIDRetriever()
                        }
                    })
                    .then( (data) => {
                        if (data.status === 200){
                            this.setState({itinerarySaved: true});
                            request({
                                url: getSystemSettings().api + '/inntopia/session?salesid=' + SalesIDRetriever(),
                                method: 'GET'
                            })
                            .then( (result) => {
                                setSessionId(result.data.SessionId);
                            });
                        }
                    });
                }else{
                    this.attachGuestModalOpen(true);
                }
            });
        }else{
            // TODO: save itinerary for b2c
        }
    }

    attachGuestModalOpen = (value) => {
        this.setState({attachGuestModal: value})
    }

    handleAttachGuestModalOpen(){
        this.setState({attachGuestModal: false}, () =>{
            history.push('#attachGuestsModal')
        })
    }

    render(){
        if (this.state.loading === true){
            return(
                <div>
                    <LoadingScreen css={{height: '200px'}}/>
                </div>
            )
        }

        if (this.state.itinerarySaved) {
            return (
                <React.Fragment>
                    <SEO title="Itinerary Saved" />
                    <div className="emptyCart">
                        <div className="summaryCart">
                            <div className="noItems">
                                <span>Itinerary Saved</span>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        if (this.state.cart.length <= 0) {
            return (
                <React.Fragment>
                    <SEO title="Cart" />
                    <div className="emptyCart">
                        <div className="summaryCart">
                            <div className="noItems">
                                <ShoppingCart size={64} />
                                <span>Your cart is empty!</span>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <SEO title="Cart" />
                <div className="cartSection">
                    <div className="topBar">
                        <div className="title">
                            Place your order for:
                        </div>
                        <div className="itinerary-id">{
                            this.props.readOnly ? 
                            <React.Fragment>
                                <span className="id">Your Itinerary #{this.state.itineraryId} has been succesfully purchased</span>
                                <Button className="save" onClick={this.saveItinerary.bind(this)}>Save itinerary</Button>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <span className="id">Itinerary #{this.state.itineraryId}</span>
                                <Button className="save" onClick={this.saveItinerary.bind(this)}>Save itinerary</Button>
                            </React.Fragment>
                        }
                            
                        </div>
                    </div>
                    <Products deleteItem={this.removeItemModalOpen.bind(this)} cart={this.state.cart} />
                    <div className="bottom">
                        <div className="upselling">
                            <h3 className="topTitle">Continue shopping</h3>
                            <div className="categories">
                                <TabGenerator upselling={true} />
                            </div>
                            <h3 className="bottomTitle">Book in advance and save</h3>
                        </div>
                        <div className="summary">
                            <div><div className="summaryName">Subtotal</div><div className="summaryName"><Currency float={true} value={this.state.total}/></div></div>
                            {
                                this.state.taxes > 0 ?
                                    <React.Fragment>
                                        <div onClick={()=>this.taxesModalOpen(true)} className="taxes">Taxes & fees:</div>
                                        <div className="summaryName">
                                            <Currency float={true} value={this.state.taxes}/>
                                        </div>
                                    </React.Fragment>
                            : null
                            }
                            <div><div className="summaryName">Total</div><div className="summaryName total"><Currency float={true} value={this.state.total + this.state.taxes}/></div></div>
                            <TotalDueNow/>
                        </div>
                    </div>
                    <div className="checkout">
                        <Button className="goToCheckout" onClick={()=>this.gotoRegister()}>Proceed to checkout <Icon.ChevronRight/></Button>
                        <div className="itinerary-id">
                            <Button className="save" onClick={this.saveItinerary.bind(this)}>Save itinerary</Button>
                        </div>
                    </div>
                </div>
                <Modal
                    open={this.state.removeItemModal}
                    title="Are you sure?"
                    className="deleteItem"
                    closeFn={this.removeItemModalOpen.bind(this, {}, false)}
                    footerContent={
                        <React.Fragment>
                            <Button onClick={()=>this.handleDeleteItem()} className="secondary">Remove Product</Button>
                            <Button onClick={()=>this.removeItemModalOpen({}, false)} className="primary">Keep Item</Button>
                        </React.Fragment>
                    }
                >
                    <div className="body">
                        <div>{this.state.deletedItem.SupplierName}</div>
                        <div>{this.state.deletedItem.ProductName}</div>
                    </div>
                </Modal>

                <Modal
                    open={this.state.taxesModal}
                    title="Taxes and fees"
                    className="taxes"
                    closeFn={this.taxesModalOpen.bind(this, false)}
                    footerContent={
                        <React.Fragment>
                            <Button onClick={()=>this.taxesModalOpen(false)} className="secondary">CLOSE</Button>
                        </React.Fragment>
                    }
                >
                    <div className="body">
                        {this.state.taxesList.map((tax, idx) => {
                            return(
                                <div className="feesandtaxes" key={idx}>
                                    <div className="name">
                                        {tax.FeeTaxTypeName ? tax.FeeTaxTypeName : tax.name}
                                    </div>
                                    <div className="price">
                                        {tax.Amount === 0 || !tax.Amount ? <Currency currency='usd' float={true} value={tax.price}/> : <Currency currency='usd' float={true} value={tax.Amount}/>}
                                    </div>
                                </div>
                            )
                        })}
                        <div className="name">
                            TOTAL
                        </div>
                        <div className="price">
                            <Currency currency='usd' float={true} value={this.state.taxes}/>
                        </div>
                    </div>
                </Modal>

                <Modal
                    open={this.state.attachGuestModal}
                    title="Attach Guest"
                    className="attach"
                    closeFn={this.attachGuestModalOpen.bind(this, false)}
                    footerContent={
                        <React.Fragment>
                            <Button onClick={()=>this.attachGuestModalOpen(false)} className="secondary">Close</Button>
                            <Button onClick={()=>this.handleAttachGuestModalOpen()} className="primary">Attach Guest</Button>
                        </React.Fragment>
                    }
                >
                    <div className="body">
                        No guest is attached at the moment, please attach one.
                    </div>
                </Modal>

            </React.Fragment>
        );

    };
}
