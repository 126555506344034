export default (_theme) => {
    const theme = _theme.theme;

    if(!theme){
        return {};
    }

    const themeVariables = {
        general_background_color: theme.general.background,
        //topbar
        topbar_background_color: theme.topbar.background,
        topbar_message_color: theme.topbar.message_color,
        topbar_message_bold_color: theme.topbar.message_bold_color,
        topbar_message_link_color: theme.topbar.message_link_color,
        //topbar mobile
        topbar_mobile_background: theme.topbar.mobile.background,
        topbar_menu_icon: theme.topbar.mobile.menu_icon,
        topbar_cart_icon: theme.topbar.mobile.cart_icon,
        topbar_badge_background: theme.topbar.mobile.cart_badge_background,
        topbar_badge_color: theme.topbar.mobile.cart_badge_color,
        //searchbar
        searchbar_background: theme.searchbar.background,
        searchbar_color: theme.searchbar.color,
        searchbar_input_border: theme.searchbar.input_border,
        searchbar_input_background: theme.searchbar.input_background,
        searchbar_input_color: theme.searchbar.input_color,
        searchbar_button_background: theme.searchbar.button_background,
        searchbar_button_color: theme.searchbar.button_color,
        //mobile search bar
        mobilesearch_background: theme.mobilesearch.background,
        mobilesearch_color: theme.mobilesearch.color,
        //navbar
        navbar_tab_background: theme.navbar.tab.background,
        navbar_tab_color: theme.navbar.tab.color,
        navbar_tab_border: theme.navbar.tab.border,
        navbar_tab_active_background: theme.navbar.tab.active.background,
        navbar_tab_active_color: theme.navbar.tab.active.color,
        navbar_tab_hover_background: theme.navbar.tab.hover.background,
        navbar_tab_hover_color: theme.navbar.tab.hover.color,
        navbar_tab_shoppingcart_background: theme.navbar.tab.cart.background,
        navbar_tab_shoppingcart_color: theme.navbar.tab.cart.color,
        //tabs
        tabbar_color: theme.tabbar.color,
        tabbar_selected_background: theme.tabbar.selected.background,
        tabbar_selected_color: theme.tabbar.selected.color,
        tabbar_selected_border: theme.tabbar.selected.border,
        tabbar_pane_background: theme.tabbar.pane_background,
        tabbar_pane_border: theme.tabbar.pane_border,
        //lodging
        lodginglist_background: theme.lodging.background,
        lodginglist_filter_head_background: theme.lodging.filter.head.background,
        lodginglist_filter_head_color: theme.lodging.filter.head.color,
        lodginglist_filter_content_background: theme.lodging.filter.body.background,
        lodginglist_filter_content_slider_label_color: theme.lodging.filter.body.slider.label_color,
        lodginglist_filter_content_slider_track_color: theme.lodging.filter.body.slider.track_color,
        lodginglist_filter_content_slider_track_active_color: theme.lodging.filter.body.slider.track_active_color,
        lodginglist_filter_content_slider_track_point_color: theme.lodging.filter.body.slider.track_point_color,
        //activities
        activitylist_background: theme.activities.background,
        //summarycart
        summarycart_general_color: theme.summarycart.color,
        summarycart_general_background: theme.summarycart.background,
        summarycart_group_background: theme.summarycart.group.background,
        summarycart_group_color: theme.summarycart.group.color,
        summarycart_product_background: theme.summarycart.product.background,
        summarycart_product_border: theme.summarycart.product.border,
        summarycart_product_supplier_color: theme.summarycart.product.supplier_color,
        summarycart_product_product_color: theme.summarycart.product.product_color,
        summarycart_product_dateguest_color: theme.summarycart.product.dateguest_color,
        summarycart_product_dateguest_span_color: theme.summarycart.product.dateguest_span_color,
        summarycart_remove_background: theme.summarycart.remove.background,
        summarycart_remove_color: theme.summarycart.remove.color,
        summarycart_cta_background: theme.summarycart.cta.background,
        summarycart_cta_color: theme.summarycart.cta.color,
        //items
        // lodging
        item_lodging_primary_cta_background: theme.lodging.supplier.cta.background,
        item_lodging_primary_cta_color: theme.lodging.supplier.cta.color,
        item_lodging_border_color: theme.lodging.supplier.border_color,
        item_lodging_village_icon_color: theme.lodging.supplier.village.icon_color,
        item_lodging_village_text_color: theme.lodging.supplier.village.text_color,
        item_lodging_title_color: theme.lodging.supplier.title_color,
        item_lodging_description_color: theme.lodging.supplier.description.color,
        item_lodging_description_link_color: theme.lodging.supplier.description.link_color,
        item_lodging_map_color: theme.lodging.map.color,
        //lodging units
        item_lodging_unit_title_color: theme.lodging.units.title_color,
        item_lodging_unit_savings_color: theme.lodging.units.savings_color,
        item_lodging_unit_description_color: theme.lodging.units.description.color,
        item_lodging_unit_description_link_color: theme.lodging.units.description.link_color,
        item_lodging_unit_primary_cta_background: theme.lodging.units.cta.background,
        item_lodging_unit_primary_cta_color: theme.lodging.units.cta.color,
        item_lodging_unit_booknow_cta_background: theme.lodging.units.cta.booknow.background,
        item_lodging_unit_booknow_cta_color: theme.lodging.units.cta.booknow.color,
        item_lodging_unit_booknow_cta_border_color: theme.lodging.units.cta.booknow.border_color,
        item_lodging_unit_selectoffer_cta_background: theme.lodging.units.cta.selectoffer.background,
        item_lodging_unit_selectoffer_cta_color: theme.lodging.units.cta.selectoffer.color,
        item_lodging_unit_selectoffer_cta_border_color: theme.lodging.units.cta.selectoffer.border_color,
        //activities
        item_activities_primary_cta_background: theme.activities.supplier.cta.background,
        item_activities_primary_cta_color: theme.activities.supplier.cta.color,
        item_activities_border_color: theme.activities.supplier.border_color,
        item_activities_village_icon_color: theme.activities.supplier.village.icon_color,
        item_activities_village_text_color: theme.activities.supplier.village.text_color,
        item_activities_title_color: theme.activities.supplier.title_color,
        item_activities_description_color: theme.activities.supplier.description.color,
        item_activities_description_link_color: theme.activities.supplier.description.link_color,
        //activity units
        item_activities_unit_headers_color: theme.activities.units.headers_color,
        item_activities_unit_title_color: theme.activities.units.title_color,
        item_activities_unit_description_color: theme.activities.units.description.color,
        item_activities_unit_description_link_color: theme.activities.units.description.link_color,
        item_activities_unit_primary_cta_background: theme.activities.units.cta.background,
        item_activities_unit_primary_cta_color: theme.activities.units.cta.color,
        //packages
        packages_list_background: theme.packages.background,
        packages_item_title: theme.packages.item.title,
        packages_item_description: theme.packages.item.description.color,
        packages_item_description_link_color: theme.packages.item.description.link_color,
        packages_item_cta_background: theme.packages.item.cta.background,
        packages_item_cta_color: theme.packages.item.cta.color,
        //package individual
        package_background: theme.package.background,
        package_title_color: theme.package.title_color,
        package_description_color: theme.package.description.color,
        package_description_link_color: theme.package.description.link_color,
        package_aside_cta_background: theme.package.aside.cta.background,
        package_aside_cta_color: theme.package.aside.cta.color,
        package_components_navbar_background: theme.package.components.navbar.background,
        package_components_navbar_required_border: theme.package.components.navbar.required_border,
        package_components_navbar_optional_border: theme.package.components.navbar.optional_border,
        package_components_navbar_scrollbuttons_background: theme.package.components.navbar.scrollbuttons_background,
        package_components_navbar_scrollbuttons_border: theme.package.components.navbar.scrollbuttons_border,
        package_components_navbar_tab_background: theme.package.components.navbar.tab.background,
        package_components_navbar_tab_color: theme.package.components.navbar.tab.color,
        package_components_navbar_tab_border_left: theme.package.components.navbar.tab.border_left,
        package_components_navbar_tab_border_right: theme.package.components.navbar.tab.border_right,
        package_components_navbar_tab_active_color: theme.package.components.navbar.tab.active.color,
        package_components_navbar_tab_active_background: theme.package.components.navbar.tab.active.background,
        package_components_navbar_tab_active_icon_color: theme.package.components.navbar.tab.active.icon_color,
        package_components_navbar_tab_active_icon_background: theme.package.components.navbar.tab.active.icon_background,
        package_component_pane_border: theme.package.component.pane_border,
        package_component_dates_selected_color: theme.package.component.dates_selected_color,
        //shopping cart
        cart_background: theme.cart.background,
        cart_header_title_color: theme.cart.header.title_color,
        cart_header_itinerary_color: theme.cart.header.itinerary.color,
        cart_header_itinerary_save_color: theme.cart.header.itinerary.save_color,
        cart_group_background: theme.cart.group.background,
        cart_group_color: theme.cart.group.color,
        cart_product_background: theme.cart.product.background,
        cart_product_photo_background: theme.cart.product.photo_background,
        cart_product_photo_icon_color: theme.cart.product.photo_icon_color,
        cart_product_supplier_color: theme.cart.product.supplier_color,
        cart_product_product_color: theme.cart.product.product_color,
        cart_product_datequantity_color: theme.cart.product.datequantity_color,
        cart_product_remove_background: theme.cart.product.remove.background,
        cart_product_remove_color: theme.cart.product.remove.color,
        cart_product_savings_color: theme.cart.product.savings_color,
        cart_product_selected_package_color: theme.cart.product.selected_package_color,
        cart_upselling_background: theme.cart.upselling.background,
        cart_upselling_top_title_color: theme.cart.upselling.titles.top_color,
        cart_upselling_bottom_title_color: theme.cart.upselling.titles.bottom_color,
        cart_upselling_category_color: theme.cart.upselling.category.color,
        cart_upselling_category_hover_color: theme.cart.upselling.category.hover.color,
        cart_upselling_category_icon_color: theme.cart.upselling.category.icon_color,
        cart_upselling_category_icon_hover_color: theme.cart.upselling.category.hover.icon_color,
        cart_summary_subtotal: theme.cart.summary.subtotal,
        cart_summary_total_background: theme.cart.summary.total_background,
        cart_summary_taxes_link_color: theme.cart.summary.taxes_link_color,
        cart_mobile_bottom_cta_background: theme.cart.mobile.bottom.cta.background,
        cart_mobile_bottom_cta_color: theme.cart.mobile.bottom.cta.color,
        //checkout
        checkout_buttons_cart_border: theme.checkout.buttons.cart.border,
        checkout_buttons_cart_background: theme.checkout.buttons.cart.background,
        checkout_buttons_cart_color: theme.checkout.buttons.cart.color,
        checkout_buttons_cart_hover_border: theme.checkout.buttons.cart.hover.border,
        checkout_buttons_cart_hover_background: theme.checkout.buttons.cart.hover.background,
        checkout_buttons_cart_hover_color: theme.checkout.buttons.cart.hover.color,
        //suppliers
        suppliers_link_color: theme.suppliers.link_color,
        suppliers_link_hover_color: theme.suppliers.link_hover_color,
        //supplier page
        supplier_title_color: theme.supplier.title_color,
        supplier_address_color: theme.supplier.address.color,
        supplier_address_link_color: theme.supplier.address.link_color,
        supplier_aside_button_background: theme.supplier.aside.button.background,
        supplier_aside_button_border: theme.supplier.aside.button.border,
        supplier_aside_button_color: theme.supplier.aside.button.color,
        //modals
        modal_background: theme.modal.background,
        modal_header_background: theme.modal.header.background,
        modal_header_color: theme.modal.header.color,
        modal_header_icon_color: theme.modal.header.icon_color,
        modal_footer_background: theme.modal.footer.background,
        modal_footer_cta_primary_color: theme.modal.footer.cta.primary.color,
        modal_footer_cta_primary_background: theme.modal.footer.cta.primary.background,
        modal_footer_cta_secondary_color: theme.modal.footer.cta.secondary.color,
        modal_footer_cta_secondary_background: theme.modal.footer.cta.secondary.background,
        //drawer
        drawer_background: theme.drawer.background,
        drawer_categoriesmenu_option_background: theme.drawer.categories.background,
        drawer_categoriesmenu_option_color: theme.drawer.categories.color,
        drawer_categoriesmenu_option_border: theme.drawer.categories.border,
        drawer_categoriesmenu_option_icon_color: theme.drawer.categories.icon_color,
        drawer_categoriesmenu_option_label_color: theme.drawer.categories.label_color,
        drawer_categoriesmenu_option_hover_background: theme.drawer.categories.hover_background,
        drawer_categoriesmenu_option_hover_icon_color: theme.drawer.categories.hover_icon_color,
        drawer_categoriesmenu_option_hover_label_color: theme.drawer.categories.hover_label_color,
        drawer_bottommenu_background: theme.drawer.bottommenu.background,
        drawer_bottommenu_option_label_color: theme.drawer.bottommenu.options.label_color,
        drawer_bottommenu_option_icon_color: theme.drawer.bottommenu.options.icon_color,
        drawer_bottommenu_option_hover_label_color: theme.drawer.bottommenu.options.hover_label_color,
        drawer_bottommenu_option_hover_icon_color: theme.drawer.bottommenu.options.hover_icon_color,
        drawer_bottommenu_close_color: theme.drawer.bottommenu.close_color,
        //stepper
        stepper_background: theme.stepper.background,
        stepper_border: theme.stepper.border_color,
        stepper_color: theme.stepper.color,
        stepper_dropdown_color: theme.stepper.dropdown.color,
        stepper_dropdown_background: theme.stepper.dropdown.background,
        stepper_dropdown_border: theme.stepper.dropdown.border,
        //yield view
        yieldview_calendar_head_background: theme.yield_view.header.background,
        yieldview_calendar_head_color: theme.yield_view.header.color,
        yieldview_product_cta_primary_background: theme.yield_view.header.background,
        yieldview_product_cta_primary_color: theme.yield_view.header.color,
        //footer
        footer_background: theme.footer.background,
        footer_link_color: theme.footer.link.color,
        footer_link_hover_color: theme.footer.link.hover.color,
        footer_text_color: theme.footer.text.color,
        footer_title_color: theme.footer.title.color,
        footer_bold_color: theme.footer.bold.color,
        //footer
        socket_background: theme.socket.background,
        socket_color: theme.socket.color,
    }
    //b2b
    if (theme.b2b) {
        // topbar
        themeVariables.b2b_topbar_background = theme.b2b.topbar.background;
        themeVariables.b2b_topbar_switches_background = theme.b2b.topbar.switches.background;
        themeVariables.b2b_topbar_switches_nonselected_color = theme.b2b.topbar.switches.nonselected.color;
        themeVariables.b2b_topbar_switches_nonselected_background = theme.b2b.topbar.switches.nonselected.background;
        themeVariables.b2b_topbar_switches_selected_background = theme.b2b.topbar.switches.selected.background;
        themeVariables.b2b_topbar_switches_selected_color = theme.b2b.topbar.switches.selected.color;
        themeVariables.b2b_topbar_buttons_background = theme.b2b.topbar.buttons.background;
        themeVariables.b2b_topbar_buttons_color = theme.b2b.topbar.buttons.color;
        // login form
        themeVariables.b2b_login_background = theme.b2b.login.background;
        themeVariables.b2b_login_cta_border = theme.b2b.login.cta.border;
        themeVariables.b2b_login_cta_background = theme.b2b.login.cta.background;
        themeVariables.b2b_login_cta_color = theme.b2b.login.cta.color;
        // dashboard
        themeVariables.b2b_dashboard_option_color = theme.b2b.dashboard.option.color;
        themeVariables.b2b_dashboard_option_border = theme.b2b.dashboard.option.border;
        themeVariables.b2b_dashboard_option_background = theme.b2b.dashboard.option.background;
        themeVariables.b2b_dashboard_option_hover_border = theme.b2b.dashboard.option.hover.border;
        themeVariables.b2b_dashboard_option_hover_background = theme.b2b.dashboard.option.hover.background;
        themeVariables.b2b_dashboard_option_icon_color = theme.b2b.dashboard.option.icon.color;
        themeVariables.b2b_dashboard_option_hover_icon_color = theme.b2b.dashboard.option.icon.hover;
        themeVariables.b2b_dashboard_option_active_border = theme.b2b.dashboard.option.active.border;
        themeVariables.b2b_dashboard_option_active_background = theme.b2b.dashboard.option.active.background;
        themeVariables.b2b_dashboard_option_active_icon_color = theme.b2b.dashboard.option.icon.active;
        // dashboard sidebar
        themeVariables.b2b_dashboard_sidebar_background = theme.b2b.dashboard.sidebar.background;
        // sections general
        themeVariables.b2b_section_buttons_link_color = theme.b2b.section.buttons.link.color;
        themeVariables.b2b_section_buttons_link_hover_color = theme.b2b.section.buttons.link.hover.color;
        themeVariables.b2b_section_buttons_primary_background = theme.b2b.section.buttons.primary.background;
        themeVariables.b2b_section_buttons_primary_color = theme.b2b.section.buttons.primary.color;
        themeVariables.b2b_section_buttons_primary_hover_background = theme.b2b.section.buttons.primary.hover.background;
        themeVariables.b2b_section_buttons_primary_hover_color = theme.b2b.section.buttons.primary.hover.color;
        themeVariables.b2b_section_buttons_secondary_background = theme.b2b.section.buttons.secondary.background;
        themeVariables.b2b_section_buttons_secondary_color = theme.b2b.section.buttons.secondary.color;
        themeVariables.b2b_section_buttons_secondary_hover_background = theme.b2b.section.buttons.secondary.hover.background;
        themeVariables.b2b_section_buttons_secondary_hover_color = theme.b2b.section.buttons.secondary.hover.color;
        themeVariables.b2b_section_buttons_add_background = theme.b2b.section.buttons.add.background;
        themeVariables.b2b_section_buttons_add_color = theme.b2b.section.buttons.add.color;
        themeVariables.b2b_section_buttons_search_background = theme.b2b.section.buttons.search.background;
        themeVariables.b2b_section_buttons_search_color = theme.b2b.section.buttons.search.color;
        themeVariables.b2b_section_buttons_clear_background = theme.b2b.section.buttons.clear.background;
        themeVariables.b2b_section_buttons_clear_color = theme.b2b.section.buttons.clear.color;
        // section details
        themeVariables.b2b_section_selector_border = theme.b2b.section.selector.border;
        themeVariables.b2b_section_selector_color = theme.b2b.section.selector.color;
        themeVariables.b2b_section_selector_background = theme.b2b.section.selector.background;
        themeVariables.b2b_section_switcher_background = theme.b2b.section.switcher.background;
        themeVariables.b2b_section_switcher_button_background = theme.b2b.section.switcher.button.background;
        themeVariables.b2b_section_switcher_button_color = theme.b2b.section.switcher.button.color;
        themeVariables.b2b_section_switcher_button_selected_background = theme.b2b.section.switcher.button.selected.background;
        themeVariables.b2b_section_switcher_button_selected_color = theme.b2b.section.switcher.button.selected.color;
    }
    return themeVariables;
}